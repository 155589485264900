import React from "react";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";
import $ from 'jquery';

import './file.css';
import {InputFrameworkField} from "../InputFramework/InputFramework";


//文件类型的字段*
class FieldFile extends React.Component {
    constructor(props) {
        super(props);
        this.getContent = this.getContent.bind(this);
        this.getInputInfo = this.getInputInfo.bind(this);
    }

    componentDidMount() {
    }

    getInputInfo() {
        let resultValue = tool.getAttribute(this, ['props', 'param', 'resultValue',]);
        let record = tool.getAttribute(this, ['props', 'param', 'record',]);
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);

        if (action === 'create' || action === 'copy') {
            let columnCreatePath = tool.getAttribute(this, ['props', 'param', 'columnCreatePath',]);
            let columnCreate = tool.getStateSpace(columnCreatePath);

            // 兼容获取columnCreate/columnUpdate
            let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
            if (columnInfo) columnCreate = columnInfo;

            let title = tool.getAttribute(columnCreate, 'title');
            let help = tool.getAttribute(columnCreate, 'help');
            if (!help) help = title;

            return {
                defaultValue: resultValue,
                title: title,
                help: help,
                columnCreate: columnCreate,
                columnCreatePath: columnCreatePath,
                record: record,
                action: action,
            };
        } else if (action === 'update') {
            let columnUpdatePath = tool.getAttribute(this, ['props', 'param', 'columnUpdatePath',]);
            let columnUpdate = tool.getStateSpace(columnUpdatePath);
            let title = tool.getAttribute(columnUpdate, 'title');
            let help = tool.getAttribute(columnUpdate, 'help');
            if (!help) help = title;

            return {
                defaultValue: resultValue,
                title: title,
                help: help,
                columnUpdate: columnUpdate,
                columnUpdatePath: columnUpdatePath,
                record: record,
                action: action,
            };
        }
    }

    getContent() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);

        let inputInfo = this.getInputInfo();
        let accept = tool.getAttribute(columnsCreate, [columnKey, 'accept']);
        let action = inputInfo.action;
        if (action === 'update') {
            let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
            let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
            accept = tool.getAttribute(columnsUpdate, [columnKey, 'accept']);
        }

        //制作临时id
        let inputId = Math.ceil(Math.random() * 10).toString();

        return (
            <div>
                <input
                    id={inputId}
                    className={'file-input'}
                    type={'file'}
                    onChange={event => {
                        let action = inputInfo.action;
                        let files = event.target.files;
                        let file = null;
                        if (files.length > 0) file = event.target.files[0];

                        if (action === 'update') {
                            let record = tool.getAttribute(infoModal, ['paramModal', 'record',]);
                            let dataKey = `update__${record.id}`;
                            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                            let data = tool.getStateSpace(dataPath);
                            data = Object.assign({}, data);
                            data[columnKey] = file;
                            tool.setStateSpace(dataPath, data);
                        } else if (action === 'create' || action === 'copy') {
                            let dataKey = `create__x`;
                            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                            let data = tool.getStateSpace(dataPath);
                            data = Object.assign({}, data);
                            data[columnKey] = file;
                            tool.setStateSpace(dataPath, data);
                        }
                    }}
                    accept={accept ? accept : '*'}
                />

                <button
                    className={'file-button'}
                    onClick={() => $(`#${inputId}`).click()}
                >
                    点击选择
                </button>

            </div>
        )
    }

    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let record = tool.getAttribute(paramModal, 'record');
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);

        // 兼容获取columnCreate/columnUpdate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) columnCreate = columnInfo;
        if (columnInfo) columnUpdate = columnInfo;


        let title = tool.getAttribute(columnUpdate, 'title');
        let help = tool.getAttribute(columnUpdate, 'help');

        let accept = tool.getAttribute(columnUpdate, 'accept');

        if (action === 'create' || action === 'copy') {
            title = tool.getAttribute(columnCreate, 'title');
            help = tool.getAttribute(columnCreate, 'help');
            accept = tool.getAttribute(columnCreate, 'accept');
        }


        //得到正在储存的文件
        let originFile;
        if (action === 'create' || action === 'copy') {
            let dataKey = `create__x`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            if (!data) data = {};
            originFile = data[columnKey];
        } else if (action === 'update') {
            let dataKey = `update__${record.id}`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            if (!data) data = {};
            originFile = data[columnKey];
        }


        let fileInfo;
        if (originFile) {
            //计算文件大小
            let back = 'B';
            let fileSize = originFile.size;
            if (fileSize > 1024) {
                fileSize = fileSize / 1024;
                back = 'Kb';
            }
            if (fileSize > 1024) {
                fileSize = fileSize / 1024;
                back = 'Mb';
            }
            if (fileSize > 1024) {
                fileSize = fileSize / 1024;
                back = 'Gb';
            }
            if (fileSize > 1024) {
                fileSize = fileSize / 1024;
                back = 'Pb';
            }
            fileSize = fileSize.toFixed(2);
            let sizeInfo = `${fileSize}${back}`;

            fileInfo = (<div className={'file-infos'}>
                <div className={'file-info-line'}>
                    <div className={'file-info-left'}>已经选择文件:</div>
                    {originFile.name}
                </div>
                <div className={'file-info-line'}>
                    <div className={'file-info-left'}>文件大小:</div>
                    {sizeInfo}
                </div>
                <div className={'file-info-line'}>
                    <div className={'file-info-left'}>文件类型:</div>
                    {originFile.type}
                </div>
            </div>);
        }


        return (<div>
            <InputFrameworkField param={{
                getContent: this.getContent,
                title: title,
                help: help,
                action: action,
            }}/>
            <br/>
            <div>{'支持的文件类型:' + (accept ? accept : '*')}</div>
            <br/>
            {fileInfo}
        </div>);
    }
}

function mapStateToFile(state) {
    return {}
}

FieldFile = connect(mapStateToFile)(FieldFile);

export {FieldFile,};
