import {tool} from "../../tools/tools";


function utcTime(param) {

    let value = param.value;
    if (value === undefined || value === null) {
        value = null;
    } else {
        value = value.toString();
    }

    // utc时间显示为中国时间 +8
    value = tool.utc_to_china(value);

    return value;
}

export {utcTime};

