import React from "react";

import {Image} from "antd";

import {tool} from "../../tools/tools";

function imgPath(param) {
    let value = param.value;
    if (!value) return null;
    // let url = tool.get_url(value);
    let url = tool.path_join(value);
    return (<div>
        <Image
            src={url}
            style={{
                height: '50px',
                width: '50px',
            }}
            width={'100%'}
            height={'100%'}
        />
    </div>)
}

export {imgPath};

