import React from "react";
import {Modal, Divider, Button} from "antd";

import {tool} from "../tools/tools";
import {connect} from "react-redux";


//分步创建
class CreateTablePart extends React.Component {
    constructor(props) {
        super(props);
        this.getColumnsCreatePart = this.getColumnsCreatePart.bind(this);
        this.getOk = this.getOk.bind(this);
        this.getNowPage = this.getNowPage.bind(this);
        this.getNowPagePath = this.getNowPagePath.bind(this);
        this.isShow = this.isShow.bind(this);
    }

    getNowPagePath() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let nowPagePath;
        nowPagePath = spacePrivatePath.concat(['createPart', 'nowPage']);
        return nowPagePath;
    }

    getNowPage() {
        let nowPagePath = this.getNowPagePath();
        let nowPage = tool.getStateSpace(nowPagePath);
        if (!nowPage) nowPage = 1;
        return nowPage;
    }

    getOk() {
        let ok;
        // 检测是否存在下一页
        let nowPage = this.getNowPage();

        let nextPage = nowPage + 1;
        let nextPageColumns = this.getColumnsCreatePart(nextPage);
        let last = false;
        if (!nextPageColumns) last = true;

        let onOk = () => this.onOk(false);
        if (last) onOk = () => this.onOk(true);

        let okText = '保存并继续';
        if (last) okText = '完成';

        ok = {
            onOk: onOk,
            okText: okText,
        };
        return ok;
    }

    getFieldReact(param) {
        let columnKey = tool.getAttribute(param, 'columnKey');
        let defaultValue = tool.getAttribute(param, 'defaultValue');

        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let record = tool.getAttribute(infoModal, 'record');
        let columnsCreatePart = this.getColumnsCreatePart();
        let columnInfo = columnsCreatePart[columnKey];


        //字段类型
        let fieldType = columnInfo.type;
        if (!fieldType) fieldType = 'char';


        let fieldMapping = tool.getStateSpace(['setting', 'fieldMapping',]);
        let func = tool.getAttribute(fieldMapping, fieldType);

        if (!this.isShow()) return null;


        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        // let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let nowPage;
        nowPage = tool.getAttribute(spacePrivate, ['createPart', 'nowPage',]);

        if (!nowPage) nowPage = 1;
        // let updatePartSpaceKey = `columnsUpdate_${nowPage}`;

        //更新字段
        // let columnsCreatePartPath = spacePublicPath.concat([updatePartSpaceKey,]);
        return (
            <div key={columnKey}>
                {func({
                    record: record,
                    action: 'create',
                    columnKey: columnKey,
                    defaultValue: defaultValue,
                    // updateSpacePath: columnsCreatePartPath,
                    spacePrivatePath: spacePrivatePath,
                    columnInfo: columnInfo,
                })}
                <Divider/>
            </div>
        );
    }

    getColumnsCreatePart(page = null) {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        if (!this.isShow()) return null;


        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        // 计算createPartSpaceKey
        let nowPage;
        nowPage = page ? page : tool.getAttribute(spacePrivate, ['createPart', 'nowPage',]);

        if (!nowPage) nowPage = 1;
        let createPartSpaceKey = `columnsCreate_${nowPage}`;

        //更新字段
        let columnsCreatePartPath = spacePublicPath.concat([createPartSpaceKey,]);
        let columnsCreatePart = tool.getStateSpace(columnsCreatePartPath);

        // 是否使用过滤器的字段
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }
        let filtersInfo = tool.getAttribute(spacePublic, 'filters');
        let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
        let filterNow = tool.getAttribute(filtersChoices, filterValue);
        let filterColumnsCreatePart = tool.getAttribute(filterNow, createPartSpaceKey);
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterColumnsCreatePart) {
            columnsCreatePart = filterColumnsCreatePart;
        }
        return columnsCreatePart;
    }

    isShow() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Create') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let createPartPath = spacePublicPath.concat(['columnsCreate_1']);
        let createPartSpace = tool.getStateSpace(createPartPath);
        if (!createPartSpace) return null;
        return true;
    }

    getContent() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        if (!this.isShow()) return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let record = tool.getAttribute(paramModal, ['record']);


        //更新字段
        let columnsCreate = this.getColumnsCreatePart();

        //显示字段
        if (!columnsCreate) return null;

        let fieldReacts = [];
        for (let columnKey in columnsCreate) {
            if (!columnsCreate.hasOwnProperty(columnKey)) continue;

            //具体的升级列信息
            let columnCreate = tool.getAttribute(columnsCreate, columnKey,);

            //默认值映射列mapColumnKey
            let mapColumnKey = tool.getAttribute(columnCreate, 'map',);
            if (!mapColumnKey) mapColumnKey = columnKey;


            //决定是否添加这个字段
            let state = true;
            let fun_is_show = tool.getAttribute(columnCreate, 'is_show');
            if (fun_is_show) {
                let param = {infoModalPath: infoModalPath, mapColumnKey: mapColumnKey, record: record,};
                state = fun_is_show(param);
            }
            if (state) {
                fieldReacts.push(this.getFieldReact({
                    columnKey: columnKey,
                    defaultValue: null,
                }));
            }
        }

        return fieldReacts;
    }

    onCancel() {
        //左键触发检测程序允许正常运行
        tool.rightInfo = Object.assign({}, tool.rightInfo, {
            sign: false,
        },);

        //关闭对话框/清空数据
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        infoModal = Object.assign({}, infoModal, {
            nowType: null,
            paramModal: null,
        });

        tool.setStateSpace(infoModalPath, infoModal,);
    }

    onOk(last = false) {

        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        if (!this.isShow()) return null;
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let fatherRecord = tool.getAttribute(paramModal, 'fatherRecord');
        let requestPathPath = spacePublicPath.concat(['path']);
        let requestPath = tool.getStateSpace(requestPathPath);

        let dataKey = `create__x`;
        let dataPath = infoModalPath.concat(['paramModal', dataKey]);
        let data = tool.getStateSpace(dataPath);
        if (!data) data = {};

        // 仅翻页
        if (!last) {
            // 设置当前页
            let spacePrivate = tool.getStateSpace(spacePrivatePath);
            let createPart = tool.getAttribute(spacePrivate, ['createPart',]);
            let nowPage;
            if (!last) {
                nowPage = tool.getAttribute(spacePrivate, ['createPart', 'nowPage',]);
                if (!nowPage) nowPage = 1;
                nowPage += 1;
            } else {
                nowPage = 1;
            }


            createPart = Object.assign({}, createPart, {nowPage: nowPage});
            spacePrivate = Object.assign({}, spacePrivate, {
                createPart: createPart,
            });

            // 更新modal内容
            let infoModalPath = ['infoModal'];
            let infoModal = tool.getStateSpace(infoModalPath);
            infoModal = Object.assign({}, infoModal,);
            setTimeout(() => tool.setStateSpace(infoModalPath, infoModal,), 50);

            tool.setStateSpace(spacePrivatePath, spacePrivate);

            return null;
        }


        // 获取get参数
        let paramUrl = {};
        if (fatherRecord) paramUrl['father_id'] = fatherRecord.id;

        let url = tool.get_url(requestPath, false, paramUrl,);

        let stateSplitTwo = false;
        let dataObj;
        //把数据分成两部分
        let otherData = {};
        let filesData = {};
        for (let key in data) {
            if (!data.hasOwnProperty(key)) continue;
            let value = data[key];
            if (value instanceof File) {
                filesData[key] = value;
            } else {
                otherData[key] = value;
            }

        }
        otherData = JSON.stringify(otherData);
        if (JSON.stringify(filesData) !== '{}') {
            stateSplitTwo = true;
            dataObj = new FormData();
            dataObj.append('stateSplitTwo', 'true');

            dataObj.append('otherData', otherData);
            for (let fileKey in filesData) dataObj.append(`files__${fileKey}`, filesData[fileKey]);
        }


        let requestParam = {
            url: url,
            data: otherData,
            contentType: 'application/json',
            success: data => {
                //左键触发检测程序允许正常运行
                // tool.rightInfo = Object.assign({}, tool.rightInfo, {
                //     sign: false,
                //     onTableMenu: false,
                // },);
                tool.show_success(['创建成功',]);

                // 更新表单数据
                if (last) {
                    let infoModalPath = ['infoModal'];
                    let infoModal = tool.getStateSpace(infoModalPath);
                    infoModal = Object.assign({}, infoModal, {
                        nowType: null,
                        paramModal: null,
                    });
                    tool.setStateSpace(infoModalPath, infoModal,);
                }


                // 更新表格数据
                let spacePrivate = tool.getStateSpace(spacePrivatePath);
                spacePrivate = Object.assign({}, spacePrivate, {
                    stateTableOnload: 'page',
                });

                // 设置当前页
                let createPart = tool.getAttribute(spacePrivate, ['createPart',]);
                let nowPage;
                if (!last) {
                    nowPage = tool.getAttribute(spacePrivate, ['createPart', 'nowPage',]);
                    if (!nowPage) nowPage = 1;
                    nowPage += 1;
                } else {
                    nowPage = 1;
                }


                createPart = Object.assign({}, createPart, {nowPage: nowPage});
                spacePrivate = Object.assign({}, spacePrivate, {
                    createPart: createPart,
                });

                // 更新modal内容
                let infoModalPath = ['infoModal'];
                let infoModal = tool.getStateSpace(infoModalPath);
                infoModal = Object.assign({}, infoModal,);
                setTimeout(() => tool.setStateSpace(infoModalPath, infoModal,), 50);

                tool.setStateSpace(spacePrivatePath, spacePrivate);
            },
            error: data => {
                //左键触发检测程序允许正常运行
                // tool.rightInfo = Object.assign({}, tool.rightInfo, {
                //     sign: false,
                //     onTableMenu: false,
                // },);

                let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
                let columnsCreate = tool.getStateSpace(columnsCreatePath);
                tool.show_error(data, columnsCreate);
                // tool.show_error(data, columnsUpdate);
            },
        };
        //数据分成两部分
        if (stateSplitTwo) {
            requestParam['contentType'] = false;
            requestParam['processData'] = false;
            requestParam['data'] = dataObj;
        }
        if (last) tool.post(requestParam);
    }

    onPrevious() {
        let nowPage = this.getNowPage();
        if (nowPage <= 1) return null;
        nowPage -= 1;
        let nowPagePath = this.getNowPagePath();
        tool.setStateSpace(nowPagePath, nowPage);

        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        infoModal = Object.assign({}, infoModal,);
        tool.setStateSpace(infoModalPath, infoModal,);
    }

    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        if (!this.isShow()) return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');

        // 是否自定义的触发器
        // let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        // let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = this.getColumnsCreatePart();
        let react = tool.getAttribute(columnsCreate, 'react__');
        if (react) {
            let param;
            // console.log('??>>',this.props)
            param = Object.assign({}, this.props);
            return react(param);
        }


        let textTitlePath = spacePublicPath.concat(['text__create_title']);
        let textTitle = tool.getStateSpace(textTitlePath);
        if (!textTitle) textTitle = '数据创建';

        let content = this.getContent();

        // 计算onOk及okText
        let ok = this.getOk();

        // 自定义脚
        let nowPage = this.getNowPage();
        let leftButton;
        if (nowPage !== 1) leftButton = <Button onClick={() => this.onPrevious()}>上一页</Button>;

        let footer = <div>
            {leftButton}
            <Button onClick={() => ok.onOk()}>{ok.okText}</Button>
        </div>;

        return (

            <div>
                <Modal
                    title={textTitle}
                    visible={true}
                    // okText={'提交'}
                    okText={ok.okText}
                    cancelText={'关闭'}
                    // onOk={() => this.onOk()}
                    onOk={() => ok.onOk()}
                    onCancel={() => this.onCancel()}
                    footer={footer}
                >
                    {content}
                </Modal>
            </div>
        );
    }
}

function mapStateToUpdate(state) {
    return {
        infoModal: state.infoModal,
    }
}

CreateTablePart = connect(mapStateToUpdate)(CreateTablePart);

export {CreateTablePart};
