function money(param) {

    let value = param.value;
    if (value === undefined || value === null) {
        value = null;
    } else {
        value = value.toString();
    }
    if (value === null) return null;
    return value + '元';
}

export {money};

