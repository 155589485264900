import React from "react";
import {Upload} from 'antd';
import {InboxOutlined} from '@ant-design/icons';

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

const {Dragger} = Upload;


//图片路径类型的字段
class FieldMultipleImage extends React.Component {

    render() {
        let infoModalPath = ['infoModal'];
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);

        const props = {
            name: 'file',
            multiple: true,
            onChange: event => {
                event.file.percent = 100
                event.file.status = 'done'
                let fileList = event.fileList;
                let files = {};
                for (let i = 0; i < fileList.length; i++) {
                    let file = fileList[i].originFileObj;
                    files[file.uid] = true;
                }
                let valueHandle = (dataKey, dataValue) => {
                    if (dataKey.length < 6) return dataValue;
                    if (dataKey.slice(0, 6) !== 'sign__') return dataValue;
                    let uid = dataKey.slice(6, dataKey.length);
                    if (files[uid]) return dataValue;
                    return undefined;
                };
                if (action === 'update') {

                }
                if (action === 'create' || action === 'copy') {
                    let dataKey = `create__x`;
                    let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                    let data = tool.getStateSpace(dataPath);
                    data = Object.assign({}, data);

                    // 保留有效文件
                    let dataNew = {};
                    let dataKeys = Object.keys(data);
                    for (let i = 0; i < dataKeys.length; i++) {
                        let dataKey = dataKeys[i];
                        let dataValue = data[dataKey];
                        let result = valueHandle(dataKey, dataValue);
                        if (result !== undefined) {
                            dataNew[dataKey] = result;
                        }
                    }
                    tool.setStateSpace(dataPath, dataNew);
                }
            },
            beforeUpload: file => {
                if (!tool.strIsInStr('image', file.type)) {
                    let information = `${file.name} 不是图片`;
                    tool.show_info(information);
                    return Upload.LIST_IGNORE;
                }
                return true;
            },
            data: file => {
                if (action === 'update') {

                }
                if (action === 'create' || action === 'copy') {
                    let dataKey = `create__x`;
                    let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                    let data = tool.getStateSpace(dataPath);
                    data['sign__' + file.uid] = file;
                    tool.setStateSpace(dataPath, data);
                }
            },
            customRequest: (file) => {
            },
        };

        return (<div>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">单击选择文件以上传</p>
                <p className="ant-upload-hint">
                    支持单次或批量上传
                </p>
            </Dragger>
        </div>);
    }
}

function mapStateToUpdate(state) {
    return {
        setting: state.setting,
    }
}

FieldMultipleImage = connect(mapStateToUpdate)(FieldMultipleImage);

export {FieldMultipleImage,};
