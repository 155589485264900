import React from 'react';
import {tool} from "../../tools/tools";

function file(param) {
    let value = tool.getAttribute(param, ['value',]);
    if (!value) return value;
    value = tool.path_join(value);

    // 链接文字
    let textLink = tool.getAttribute(param, ['columnInfo', 'textLink',]);
    if (!textLink) textLink = '链接';

    return <a href={value} target="_blank" rel="noreferrer">{textLink}</a>;
}

export {file};

