import React from 'react';
import {Tooltip} from "antd";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './InputFramework.css'


//容纳输入框的框架
class InputFrameworkFieldUnConnected extends React.Component {
    constructor(props) {
        super(props);
        this.getFieldInfo = this.getFieldInfo.bind(this);
    }

    getFieldInfo() {
        let param = tool.getAttribute(this, ['props', 'param',]);
        let getContent = tool.getAttribute(param, 'getContent',);
        let content = getContent();
        let title = tool.getAttribute(param, 'title',);
        let help = tool.getAttribute(param, 'help',);
        let required = tool.getAttribute(param, 'required',);
        return {
            title: title,
            help: help,
            content: content,
            required: required,
        }
    }

    render() {
        let fieldInfo = this.getFieldInfo();

        let show_input = !this.props.param.no_show_input;
        let show_border = !this.props.param.no_show_border;

        //是否有内容区域边框
        let class_name_content;
        let out_height;
        if (show_input) {
            class_name_content = 'content';
            out_height = '35px'
        }

        //内容区域边框是否设置为无色
        let style_content;
        if (show_border) {
            style_content = {
                border: "#b4b4b4 solid 1px",
                display: 'inline-block',
            }
        }


        return (
            <div className={'out'} style={{
                height: out_height,
            }}>
                <Tooltip placement="topLeft" title={fieldInfo.help}>
                    <div className={'front_text_out'}>
                        <div className={'front_text_in'}>
                            {fieldInfo.title}
                        </div>
                    </div>
                </Tooltip>

                <Tooltip placement="topLeft" title={fieldInfo.help}>
                    <div className={class_name_content} style={style_content}>{fieldInfo.content}</div>
                </Tooltip>

                &nbsp;&nbsp;&nbsp;
                <div className={'required'} style={{display: 'inline-block',}}>
                    {fieldInfo.required ? '必填' : null}
                </div>

            </div>
        );
    }
}

function mapStateTpInput(state) {
    return {
        setting: state.setting,
    }
}

let InputFrameworkField = connect(mapStateTpInput)(InputFrameworkFieldUnConnected);

export {InputFrameworkField,}
