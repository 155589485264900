import React from "react";
import {Input} from 'antd';
import {InputFrameworkField} from "../InputFramework/InputFramework";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './text.css';

const {TextArea} = Input;

//Text类型的字段
class FieldText extends React.Component {
    constructor(props) {
        super(props);
        this.getContent = this.getContent.bind(this);
    }

    componentDidMount() {
        //如果是拷贝模式,需要提前写入数据,而不是等到变化
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        if (action === 'copy') {
            let infoModalPath = ['infoModal'];
            let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
            let defaultValue = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);
            let dataKey = `create__x`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            data = Object.assign({}, data);
            data[columnKey] = defaultValue;
            tool.setStateSpace(dataPath, data);
        }
    }


    getContent() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        let record = tool.getAttribute(paramModal, 'record');

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);

        // 得到默认值
        let defaultValue = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);

        //得到帮助信息
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePublic = tool.getStateSpace(spacePublicPath);

        let textHelp = tool.getAttribute(spacePublic, ['columnsCreate', columnKey, 'help']);
        if (action === 'update') textHelp = tool.getAttribute(spacePublic, ['columnsUpdate', columnKey, 'help']);

        // 兼容获取textHelp
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        let mainTextHelp = tool.getAttribute(columnInfo, 'help');
        if (mainTextHelp) textHelp = mainTextHelp;

        return (
            <TextArea
                className={'input'}
                type={'text'}
                defaultValue={defaultValue}
                onChange={event => {
                    if (action === 'update') {
                        let dataKey = `update__${record.id}`;
                        let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                        let data = tool.getStateSpace(dataPath);
                        data = Object.assign({}, data);
                        data[columnKey] = event.target.value;
                        tool.setStateSpace(dataPath, data);
                    } else if (action === 'create' || action === 'copy') {
                        let dataKey = `create__x`;
                        let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                        let data = tool.getStateSpace(dataPath);
                        data = Object.assign({}, data);
                        data[columnKey] = event.target.value;
                        tool.setStateSpace(dataPath, data);
                    }
                }}
                placeholder={textHelp}
                rows={1}
            />
        )
    }

    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);

        // 兼容获取columnCreate/columnUpdate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) columnCreate = columnInfo;
        if (columnInfo) columnUpdate = columnInfo;


        let title = tool.getAttribute(columnUpdate, 'title');
        let help = tool.getAttribute(columnUpdate, 'help');
        if (action === 'create' || action === 'copy') {
            title = tool.getAttribute(columnCreate, 'title');
            help = tool.getAttribute(columnCreate, 'help');
        }


        return <InputFrameworkField param={{
            getContent: this.getContent,
            title: title,
            help: help,
            action: action,
        }}/>;
    }
}

function mapStateToText(state) {
    return {}
}

FieldText = connect(mapStateToText)(FieldText);

export {FieldText,};
