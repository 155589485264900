import React from "react";
import {Select} from 'antd';
import {InputFrameworkField} from "../InputFramework/InputFramework";
import {connect} from "react-redux";
import {tool} from "../../../tools/tools";
import './single-search-choices.css';


//单选类型的字段(可以搜索的)
class FieldSingleSearchChoices extends React.Component {
    constructor(props) {
        super(props);
        this.getContent = this.getContent.bind(this);
    }

    updateChoices(extraParam) {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        let record = tool.getAttribute(paramModal, 'record');
        let fatherRecord = tool.getAttribute(paramModal, 'fatherRecord');
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnUpdatePath = columnsUpdatePath.concat([columnKey]);
        let columnCreatePath = columnsCreatePath.concat([columnKey]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);

        // 兼容获取columnCreate/columnUpdate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) columnCreate = columnInfo;
        if (columnInfo) columnUpdate = columnInfo;

        if (action === 'update') {
            let path = tool.getAttribute(columnUpdate, 'path');
            if (!path) return null;

            let urlParam = Object.assign({
                line_id: record.id,
                father_id: tool.getAttribute(fatherRecord, 'id'),
                record_id: record.id,
                page_size: 10000,
            }, extraParam);

            let url = tool.get_url(path, false, urlParam);

            let requestParam = {
                url: url,
                success: data => {
                    columnUpdate = Object.assign({}, columnUpdate);
                    columnUpdate['choices'] = data.results;
                    tool.setStateSpace(columnUpdatePath, columnUpdate);
                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);
        } else if (action === 'create' || action === 'copy') {
            let path = tool.getAttribute(columnCreate, 'path');
            if (!path) return null;

            let urlParam = Object.assign({
                father_id: tool.getAttribute(fatherRecord, 'id'),
                page_size: 10000,
            }, extraParam);

            let url = tool.get_url(path, false, urlParam);

            let requestParam = {
                url: url,
                success: data => {
                    columnCreate = Object.assign({}, columnCreate);
                    columnCreate['choices'] = data.results;
                    tool.setStateSpace(columnCreatePath, columnCreate);
                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);
        }

    }

    componentDidMount() {
        this.updateChoices();

        //如果是拷贝模式,需要提前写入数据,而不是等到变化
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        if (action === 'copy') {
            let infoModalPath = ['infoModal'];
            let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
            let defaultValue = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);
            let dataKey = `create__x`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            data = Object.assign({}, data);
            data[columnKey] = defaultValue;
            tool.setStateSpace(dataPath, data);
        }
    }

    getContent() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let record = tool.getAttribute(paramModal, 'record');

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);


        let choices = tool.getAttribute(columnCreate, 'choices');
        if (action === 'update') choices = tool.getAttribute(columnUpdate, 'choices');

        // 是否是必须的
        let required = tool.getAttribute(columnCreate, 'required');
        if (action === 'update') required = tool.getAttribute(columnUpdate, 'required');

        // 默认值
        let defaultValue = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);
        if (Object.prototype.toString.call(defaultValue) === '[object Object]') defaultValue = null;

        let choiceReacts = [<Select.Option value={'0'} key={-1}>{'不选择'}</Select.Option>];
        if (required) choiceReacts = [];
        if (!choices) choices = [];
        for (let choice of choices) {
            choiceReacts.push(
                <Select.Option value={choice.id} key={choice.id}>{choice.help}</Select.Option>
            );
        }
        if (action === 'create') defaultValue = false;

        return (
            <Select
                showSearch
                className={'choice-input'}
                defaultValue={defaultValue}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                onChange={target_value => {
                    if (target_value === '0') target_value = null;

                    if (action === 'update') {
                        let dataKey = `update__${record.id}`;
                        let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                        let data = tool.getStateSpace(dataPath);
                        data = Object.assign({}, data);
                        data[columnKey] = target_value;
                        tool.setStateSpace(dataPath, data);
                    } else if (action === 'create' || action === 'copy') {
                        let dataKey = `create__x`;
                        let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                        let data = tool.getStateSpace(dataPath);
                        data = Object.assign({}, data);
                        data[columnKey] = target_value;
                        tool.setStateSpace(dataPath, data);
                    }
                }}
            >
                {choiceReacts}
            </Select>
        );
    }

    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);


        let title = tool.getAttribute(columnUpdate, 'title');
        let help = tool.getAttribute(columnUpdate, 'help');
        if (action === 'create' || action === 'copy') {
            title = tool.getAttribute(columnCreate, 'title');
            help = tool.getAttribute(columnCreate, 'help');
        }


        return <InputFrameworkField param={{
            getContent: this.getContent,
            title: title,
            help: help,
            action: action,
        }}/>;
    }
}

function mapStateToSingle(state) {
    return {
        setting: state.setting,
    }
}

FieldSingleSearchChoices = connect(mapStateToSingle)(FieldSingleSearchChoices);

export {FieldSingleSearchChoices,};
