import {setting} from "../../setting";

class StoreHandles {

    constructor() {
        this.mapping = {};
        this.components = {};
    }

    getUrlParam(key) {
        let query = window.location.search.substring(1);
        let values = query.split("&");
        for (let i = 0; i < values.length; i++) {
            let pair = values[i].split("=");
            if (pair[0] === key) {
                return pair[1];
            }
        }
        return null;
    }

    initState() {
        let messageRightIsShow = localStorage.getItem("messageRightIsShow");
        let messageRight = false;
        if (messageRightIsShow !== '1') messageRight = true;
        let nowPage = this.getUrlParam('nowPage',);
        return {
            setting: setting,
            messageRightIsShow: {
                messageRightIsShow: messageRightIsShow,
                messageRight: messageRight,
            },
            nowPage: nowPage,
        };
    }

    registerHandle(action, func) {
        this.mapping[action.type] = func;
    }

    getHandle(state, action) {
        let func = this.mapping[action.type];
        if (func) return func(state, action);
        return this.initState();
    }

    registerComponent(componentInfo) {
        this.components[componentInfo.key] = componentInfo;
    }

    getComponent(componentKey) {
        let component = this.components[componentKey];
        if (component) return component;
        return this.components;
    }

}

let storeHandles = new StoreHandles();

export {storeHandles};