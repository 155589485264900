import {tool} from "../../tools/tools";

function render(param) {
    let value = param.value;
    if (value === undefined || value === null) {
        value = null;
    } else {
        value = value.toString();
    }
    let render = tool.getAttribute(param, ['columnInfo', 'render',]);
    value = render(value, param);
    return value;
}

export {render};

