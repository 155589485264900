import React from "react";

class Bottom extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          bottom: "0",
          width: "100%",
          lineHeight: "48px",
          textAlign: "center",
          borderTop: "1px solid #f0f0f0",
          background: "#fff",
        }}
      >
        <a
          href="https://beian.miit.gov.cn/"
          style={{ color: "#bbb" }}
          target="_blank"
          rel="noreferrer"
        >
          沪ICP备2021017669号-1
        </a>
      </div>
    );
  }
}

export { Bottom };
