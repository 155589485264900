import React from 'react';
import {tool} from "../../tools/tools";

function link(param) {
    let value = tool.getAttribute(param, ['value',]);
    if (!value) return value;
    // value = tool.get_url(value);

    // 链接文字
    let textLink = tool.getAttribute(param, ['columnInfo', 'textLink',]);
    if (!textLink) textLink = '链接';
    if (value.slice(0, 4) !== 'http') value = `//${value}`;
    return <a href={value} target="view_window">{textLink}</a>;
}

export {link};

