import React from "react";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './help-array.css';


//HelpArray类型的字段
class FieldDetailHelpArray extends React.Component {

    getContent() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let recordId = tool.getAttribute(this, ['props', 'param', 'record', 'id',]);

        let columnsDetailLinePath = spacePublicPath.concat(['columnsDetailLine']);
        let columnsDetailLineSpace = tool.getStateSpace(columnsDetailLinePath);

        let column = tool.getAttribute(columnsDetailLineSpace, columnKey);
        let title = tool.getAttribute(column, 'title');

        // 获取值
        let dataKey = `detail__${recordId}`;
        let paramModalPath = ['infoModal', 'paramModal',];
        let paramModal = tool.getStateSpace(paramModalPath);
        paramModal = Object.assign({}, paramModal);
        let data = paramModal[dataKey];
        if (!data) data = {};

        //迭代组件
        let value = data[columnKey];
        let valueReacts = [];
        for (let line of value) {
            valueReacts.push(
                <div key={line.id}>
                    {line.help}
                </div>
            );
        }


        return (
            <div>
                <div className={'help-array-title'}>
                    {title}
                </div>
                {valueReacts}
            </div>
        )
    }

    render() {
        let content = this.getContent();
        return (
            <div>
                {content}
            </div>
        )
    }
}

function mapStateToUpdate(state) {
    return {
        setting: state.setting,
    }
}

FieldDetailHelpArray = connect(mapStateToUpdate)(FieldDetailHelpArray);

export {FieldDetailHelpArray,};
