import React from "react";
import {Modal, Divider, Button, Progress,} from "antd";

import $ from 'jquery';
import {tool} from "../tools/tools";
import {connect} from "react-redux";


//数据创建
class CreateTable extends React.Component {
    getFieldReact(param) {
        let columnKey = tool.getAttribute(param, 'columnKey');
        let defaultValue = tool.getAttribute(param, 'defaultValue');

        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        let columnInfo = columnsCreate[columnKey];


        //字段类型
        let fieldType = columnInfo.type;
        if (!fieldType) fieldType = 'char';


        let fieldMapping = tool.getStateSpace(['setting', 'fieldMapping',]);
        let func = tool.getAttribute(fieldMapping, fieldType);

        return (
            <div key={columnKey}>
                {func({
                    action: 'create',
                    columnKey: columnKey,
                    defaultValue: defaultValue,
                })}
                <Divider/>
            </div>
        );
    }

    getContent() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Create') return null;
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');

        //创建字段
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate',]);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);

        //显示字段
        if (!columnsCreate) return null;

        let fieldReacts = [];
        for (let columnKey in columnsCreate) {
            if (!columnsCreate.hasOwnProperty(columnKey)) continue;

            //具体的创建列信息
            let columnCreate = tool.getAttribute(columnsCreate, columnKey,);

            //默认值defaultValue
            let defaultValue = tool.getAttribute(columnCreate, 'defaultValue',);

            //决定是否添加这个字段
            let state = true;
            let fun_is_show = tool.getAttribute(columnCreate, 'is_show');
            if (fun_is_show) {
                let param = {infoModalPath: infoModalPath,};
                state = fun_is_show(param);
            }
            if (state) {
                fieldReacts.push(this.getFieldReact({
                    columnKey: columnKey,
                    defaultValue: defaultValue,
                }));
            }
        }

        return fieldReacts;
    }

    getProgress() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Create') return null;
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let progressInfo = tool.getAttribute(spacePrivate, 'progressInfo');
        let stateCreateUpload = tool.getAttribute(spacePrivate, 'stateCreateUpload');
        if (!stateCreateUpload) return null;

        let percent = tool.getAttribute(progressInfo, 'percent');
        if (!percent) percent = 0;
        return <div>
            <Progress percent={percent} status="active"/>
        </div>;
    }

    onCancel() {
        //关闭对话框/清空数据
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let stateCreateUploadPath = spacePrivatePath.concat(['stateCreateUpload']);

        // let stateCreateUploadPath = spacePrivatePath.concat(['stateCreateUpload']);
        let stateCreateUpload = tool.getStateSpace(stateCreateUploadPath);
        if (stateCreateUpload) {
            tool.show_info('请耐心等待数据创建完成.');
            return null;
        }

        // tool.setStateSpace(stateCreateUploadPath, false);

        infoModal = Object.assign({}, infoModal, {
            nowType: null,
            paramModal: null,
        });
        tool.setStateSpace(infoModalPath, infoModal,);


    }

    onOk() {
        let infoModalPath = ['infoModal'];

        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Create') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let fatherRecord = tool.getAttribute(paramModal, 'fatherRecord');
        let requestPathPath = spacePublicPath.concat(['path']);
        let requestPath = tool.getStateSpace(requestPathPath);

        let dataKey = `create__x`;
        let dataPath = infoModalPath.concat(['paramModal', dataKey]);
        let data = tool.getStateSpace(dataPath);
        if (!data) data = {};

        // 获取get参数
        let paramUrl = {};
        if (fatherRecord) paramUrl['father_id'] = fatherRecord.id;

        let url = tool.get_url(requestPath, false, paramUrl);

        let stateSplitTwo = false;
        let dataObj;

        // 设置为正在上传
        let stateCreateUploadPath = spacePrivatePath.concat(['stateCreateUpload']);
        tool.setStateSpace(stateCreateUploadPath, true);
        tool.setStateSpace(infoModalPath, Object.assign({}, infoModal),);

        //把数据分成两部分
        let otherData = {};
        let filesData = {};
        for (let key in data) {
            if (!data.hasOwnProperty(key)) continue;
            let value = data[key];
            if (value instanceof File) {
                filesData[key] = value;
            } else {
                otherData[key] = value;
            }

        }
        otherData = JSON.stringify(otherData);
        if (JSON.stringify(filesData) !== '{}') {
            stateSplitTwo = true;
            dataObj = new FormData();
            dataObj.append('stateSplitTwo', 'true');

            dataObj.append('otherData', otherData);
            for (let fileKey in filesData) dataObj.append(`files__${fileKey}`, filesData[fileKey]);
        }

        // 置空进度条
        let percent = 0; //进度值（百分比制）
        let percentPath = spacePrivatePath.concat(['progressInfo', 'percent',]);
        tool.setStateSpace(percentPath, percent);
        tool.setStateSpace(infoModalPath, Object.assign({}, infoModal),);

        let requestParam = {
            url: url,
            data: otherData,
            contentType: 'application/json',
            success: (data, _, response,) => {
                let status = tool.getAttribute(response, 'status');
                if (status === 299) {
                    tool.show_success_299(data);
                } else {
                    tool.show_success(['创建成功',]);
                }

                // 清空表单数据
                let infoModal = tool.getStateSpace(infoModalPath);
                infoModal = Object.assign({}, infoModal, {
                    nowType: null,
                    paramModal: null,
                });
                tool.setStateSpace(infoModalPath, infoModal,);

                // 更新表格数据
                let spacePrivate = tool.getStateSpace(spacePrivatePath);
                spacePrivate = Object.assign({}, spacePrivate, {
                    stateTableOnload: 'page',
                    stateCreateUpload: false,
                });
                tool.setStateSpace(spacePrivatePath, spacePrivate);
            },
            error: data => {
                let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
                let columnsCreate = tool.getStateSpace(columnsCreatePath);
                tool.show_error(data, columnsCreate);
                tool.setStateSpace(stateCreateUploadPath, false);
            },
            xhr: function () { //用以显示上传进度
                let xhr = $.ajaxSettings.xhr();
                if (xhr.upload) {
                    xhr.upload.addEventListener('progress', function (event) {
                        let percent = Math.floor(event.loaded / event.total * 100); //进度值（百分比制）
                        let percentPath = spacePrivatePath.concat(['progressInfo', 'percent',]);
                        tool.setStateSpace(percentPath, percent);
                        tool.setStateSpace(infoModalPath, Object.assign({}, infoModal),);
                    }, false);
                }
                return xhr
            },
        };

        //数据分成两部分
        if (stateSplitTwo) {
            requestParam['contentType'] = false;
            requestParam['processData'] = false;
            requestParam['data'] = dataObj;
        }

        tool.post(requestParam);
    }

    isShowPart() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Create') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let createPartPath = spacePublicPath.concat(['columnsCreate_1']);
        let createPartSpace = tool.getStateSpace(createPartPath);
        if (!createPartSpace) return null;
        return true;
    }

    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Create') return null;
        if (this.isShowPart()) return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');

        let textTitlePath = spacePublicPath.concat(['text__create_title']);
        let textTitle = tool.getStateSpace(textTitlePath);
        if (!textTitle) textTitle = '数据创建';

        let content = this.getContent();
        let progress = this.getProgress();

        // 是否正在提交数据
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let stateCreateUpload = tool.getAttribute(spacePrivate, 'stateCreateUpload');
        let disabled = false;
        if (stateCreateUpload) disabled = true;

        return (

            <div>
                <Modal
                    title={textTitle}
                    visible={true}
                    // okText={'提交'}
                    // cancelText={'关闭'}
                    onOk={() => this.onOk()}
                    onCancel={() => this.onCancel()}
                    footer={<div>
                        <Button onClick={() => this.onCancel()}>关闭</Button>
                        <Button
                            onClick={() => this.onOk()}
                            type={'primary'}
                            disabled={disabled}
                        >提交</Button>
                    </div>}
                >
                    {content}
                    {progress}
                </Modal>
            </div>
        );
    }
}

function mapStateToCreate(state) {
    return {
        infoModal: state.infoModal,
    }
}

CreateTable = connect(mapStateToCreate)(CreateTable);

export {CreateTable};