import React from "react";

import {InputFrameworkField} from "../InputFramework/InputFramework";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './dynamic.css';
import {Tooltip} from "antd";


//多选类型的字段
class FieldDynamic extends React.Component {
    constructor(props) {
        super(props);
        this.getContent = this.getContent.bind(this);
    }

    updateChoices() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        let record = tool.getAttribute(paramModal, 'record');
        let fatherRecord = tool.getAttribute(paramModal, 'fatherRecord');

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnUpdatePath = columnsUpdatePath.concat([columnKey]);
        let columnCreatePath = columnsCreatePath.concat([columnKey]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);

        // 兼容获得columnUpdate/columnCreate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) columnUpdate = columnInfo;
        if (columnInfo) columnCreate = columnInfo;


        if (action === 'update') {
            let path = tool.getAttribute(columnUpdate, 'path');
            if (!path) return null;


            //字段
            let url = tool.get_url(path, false, {
                line_id: record.id,
                father_id: fatherRecord.id,
                page_size: 10000,
            });

            let requestParam = {
                url: url,
                success: data => {
                    columnUpdate = Object.assign({}, columnUpdate);
                    columnUpdate['choices'] = data.results;
                    tool.setStateSpace(columnUpdatePath, columnUpdate);
                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);
        } else if (action === 'create' || action === 'copy') {
            let path = tool.getAttribute(columnCreate, 'path');
            if (!path) return null;

            let url = tool.get_url(path, false, {page_size: 10000,});
            let requestParam = {
                url: url,
                success: data => {
                    columnCreate = Object.assign({}, columnCreate);
                    columnCreate['choices'] = data.results;
                    tool.setStateSpace(columnCreatePath, columnCreate);
                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);
        }
    }

    componentDidMount() {
        this.updateChoices();
    }

    getContent() {
        let infoModalPath = ['infoModal'];
        let paramModalPath = infoModalPath.concat(['paramModal']);
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        let record = tool.getAttribute(paramModal, 'record');

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);


        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let title = tool.getAttribute(columnUpdate, 'title');
        let help = tool.getAttribute(columnUpdate, 'help');

        // 兼容获得columnUpdate/title/help
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        let mainTextHelp = tool.getAttribute(columnInfo, 'help');
        let mainTitle = tool.getAttribute(columnInfo, 'title');
        if (mainTextHelp) help = mainTextHelp;
        if (mainTitle) title = mainTitle;
        if (columnInfo) columnUpdate = columnInfo;
        if (columnInfo) columnCreate = columnInfo;

        let defaultValues = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);
        if (!defaultValues) defaultValues = [];
        let choices = tool.getAttribute(columnCreate, 'choices');
        if (action === 'update') choices = tool.getAttribute(columnUpdate, 'choices');


        let choiceReacts = [];
        if (!choices) choices = [];
        for (let choice of choices) {

            let defaultValue;
            let index = defaultValues.findIndex(item => item.id === choice.id);
            if (index !== -1) defaultValue = defaultValues[index].value;

            let onChange = (event, choice) => {
                let dataKey = `create__x`;
                if (action === 'update') dataKey = `update__${record.id}`;
                let valuePath = paramModalPath.concat([dataKey, columnKey,]);
                let value = tool.getStateSpace(valuePath);
                value = Object.assign({}, value);
                value[choice.key] = event.target.value;
                tool.setStateSpace(valuePath, value);
            };
            let input_style = {width: '100%', height: '100%', border: 'none',};
            let getInputFrameworkContent = () => (<input
                onChange={event => onChange(event, choice)}
                style={input_style}
                defaultValue={defaultValue}
            />);
            choiceReacts.push(<div style={{marginBottom: '5px',}} key={choice.id}>
                <InputFrameworkField
                    param={{
                        getContent: getInputFrameworkContent,
                        title: choice.help,
                        help: help,
                        action: action,
                    }}/>
            </div>);
        }


        return (
            <div>
                <Tooltip title={help}>
                    <div>
                        <div className={'title'}>
                            {title}
                        </div>
                    </div>
                </Tooltip>
                <br/>
                {choiceReacts}
            </div>

        )
    }

    render() {
        return (
            <div>
                {this.getContent()}
            </div>
        )
    }
}

function mapStateToDynamic(state) {
    return {
        setting: state.setting,
    }
}

FieldDynamic = connect(mapStateToDynamic)(FieldDynamic);

export {FieldDynamic,};
