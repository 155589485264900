import React from "react";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './money.css';


//Money类型的字段
class FieldDetailMoney extends React.Component {

    getContent() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let recordId = tool.getAttribute(this, ['props', 'param', 'record', 'id',]);

        let columnsDetailLinePath = spacePublicPath.concat(['columnsDetailLine']);
        let columnsDetailLineSpace = tool.getStateSpace(columnsDetailLinePath);

        let column = tool.getAttribute(columnsDetailLineSpace, columnKey);
        let title = tool.getAttribute(column, 'title');

        // 获取值
        let dataKey = `detail__${recordId}`;
        let paramModalPath = ['infoModal', 'paramModal',]
        let paramModal = tool.getStateSpace(paramModalPath);
        paramModal = Object.assign({}, paramModal);
        let data = paramModal[dataKey];
        if (!data) data = {};

        let value = null;
        if (data[columnKey]) value = data[columnKey].toString();
        if (value !== null) value = value + '元';

        return (
            <div>
                <div className={'money-detail-title'}>
                    {title}
                </div>

                {value}
            </div>
        )
    }

    render() {
        let content = this.getContent();
        return (
            <div>
                {content}
            </div>
        )
    }
}

function mapStateToUpdate(state) {
    return {
        infoModal: state.infoModal,
    }
}

FieldDetailMoney = connect(mapStateToUpdate)(FieldDetailMoney);

export {FieldDetailMoney,};
