import React from "react";
import {DatePicker, ConfigProvider,} from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import moment from 'moment';
import {InputFrameworkField} from "../InputFramework/InputFramework";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './time.css';


//Time类型的字段
class FieldTimeYear extends React.Component {
    constructor(props) {
        super(props);
        this.getContent = this.getContent.bind(this);
    }

    componentDidMount() {
        //如果是拷贝模式,需要提前写入数据,而不是等到变化
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        if (action === 'copy') {
            let infoModalPath = ['infoModal'];
            let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
            let defaultValue = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);
            let dataKey = `create__x`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            data = Object.assign({}, data);
            data[columnKey] = defaultValue;
            tool.setStateSpace(dataPath, data);
        }
    }

    getDefaultValue() {
        let defaultValue = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);
        if (!defaultValue) return null;
        let moment_obj = moment(defaultValue, 'YYYY-MM-DDTHH:mm:ss');
        if (!moment_obj) return null;
        return moment_obj;
    }

    getContent() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let record = tool.getAttribute(paramModal, 'record');

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);

        // 得到默认值
        let defaultValue = this.getDefaultValue()

        return (
            <ConfigProvider locale={zh_CN}>
                <DatePicker
                    style={{
                        width: '100%',
                    }}
                    showTime
                    // format="YYYY-MM-DD HH:mm:ss"
                    placeholder="Start"
                    defaultValue={defaultValue}
                    onChange={time_obj => {
                        let value = moment(time_obj).format('YYYY-MM-DDTHH:mm:ssZ');
                        if (action === 'update') {
                            let dataKey = `update__${record.id}`;
                            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                            let data = tool.getStateSpace(dataPath);
                            data = Object.assign({}, data);
                            data[columnKey] = value;
                            tool.setStateSpace(dataPath, data);
                        } else if (action === 'create' || action === 'copy') {
                            let dataKey = `create__x`;
                            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                            let data = tool.getStateSpace(dataPath);
                            data = Object.assign({}, data);
                            data[columnKey] = value;
                            tool.setStateSpace(dataPath, data);
                        }
                    }}
                    picker="year"
                />
            </ConfigProvider>


        )
    }

    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);

        // 兼容获取columnsCreate/columnsUpdate
        // let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        // if (columnInfo) columnsCreate = columnInfo;
        // if (columnInfo) columnsUpdate = columnInfo;

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);

        // 兼容获取columnCreate/columnUpdate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) columnCreate = columnInfo;
        if (columnInfo) columnUpdate = columnInfo;


        let title = tool.getAttribute(columnUpdate, 'title');
        let help = tool.getAttribute(columnUpdate, 'help');
        if (action === 'create' || action === 'copy') {
            title = tool.getAttribute(columnCreate, 'title');
            help = tool.getAttribute(columnCreate, 'help');
        }

        return <InputFrameworkField param={{
            getContent: this.getContent,
            title: title,
            help: help,
            action: action,
        }}/>;
    }
}

function mapStateToTime(state) {
    return {}
}

FieldTimeYear = connect(mapStateToTime)(FieldTimeYear);

export {FieldTimeYear,};
