import React from "react";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './map.css';


//Map类型的字段
class FieldDetailMap extends React.Component {

    getContent() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let recordId = tool.getAttribute(this, ['props', 'param', 'record', 'id',]);

        let columnsDetailLinePath = spacePublicPath.concat(['columnsDetailLine']);
        let columnsDetailLineSpace = tool.getStateSpace(columnsDetailLinePath);

        let column = tool.getAttribute(columnsDetailLineSpace, columnKey);
        let title = tool.getAttribute(column, 'title');

        // 获取值
        let dataKey = `detail__${recordId}`;
        let paramModalPath = ['infoModal', 'paramModal',];
        let paramModal = tool.getStateSpace(paramModalPath);
        paramModal = Object.assign({}, paramModal);
        let data = paramModal[dataKey];
        if (!data) data = {};
        let value = data[columnKey] ? data[columnKey].toString() : null;

        // 映射值
        let map = tool.getAttribute(column, 'map');
        value = map[value];

        return (
            <div>
                <div className={'detail-title'}>
                    {title}
                </div>
                {value}
            </div>
        )
    }

    render() {
        let content = this.getContent();
        return (
            <div>
                {content}
            </div>
        )
    }
}

function mapStateToUpdate(state) {
    return {
        infoModal: state.infoModal,
    }
}

FieldDetailMap = connect(mapStateToUpdate)(FieldDetailMap);

export {FieldDetailMap,};
