import React from "react";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './bool.css';


//Bool类型的字段
class FieldDetailBool extends React.Component {

    getContent() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let recordId = tool.getAttribute(this, ['props', 'param', 'record', 'id',]);

        let columnsDetailLinePath = spacePublicPath.concat(['columnsDetailLine']);
        let columnsDetailLineSpace = tool.getStateSpace(columnsDetailLinePath);

        let column = tool.getAttribute(columnsDetailLineSpace, columnKey);
        let title = tool.getAttribute(column, 'title');

        // 获取值
        let dataKey = `detail__${recordId}`;
        let paramModalPath = ['infoModal', 'paramModal',]
        let paramModal = tool.getStateSpace(paramModalPath);
        paramModal = Object.assign({}, paramModal);
        let data = paramModal[dataKey];
        if (!data) data = {};


        //得到bool值
        let valueText = null;
        if (data[columnKey] !== undefined) {
            let textBool = tool.getAttribute(columnsDetailLineSpace, [columnKey, 'textBool']);
            if (!textBool) textBool = ['True', 'False',];
            if (data[columnKey]) {
                valueText = textBool[0];
            } else {
                valueText = textBool[1];
            }
        }

        return (
            <div>
                <div className={'detail-title'}>
                    {title}
                </div>

                {valueText}
            </div>
        )
    }

    render() {
        let content = this.getContent();
        return (
            <div>
                {content}
            </div>
        )
    }
}

function mapStateToUpdate(state) {
    return {
        setting: state.setting,
    }
}

FieldDetailBool = connect(mapStateToUpdate)(FieldDetailBool);

export {FieldDetailBool,};
