import React from 'react';
import {Input, message, Popover} from "antd";
import {SyncOutlined} from '@ant-design/icons';

import {connect} from 'react-redux';
import ExportJsonExcel from 'js-export-excel';
import {tool} from "../../tools/tools";
import {getColumnRender} from "../columns/main";


//下载组件
class DownloadUnConnected extends React.Component {

    constructor(props) {
        super(props);

        this.download = this.download.bind(this);
        this.get_sheet_data = this.get_sheet_data.bind(this);
        this.data_to_excel = this.data_to_excel.bind(this);
        this.state = {
            value: null,
            down_text: '点击下载',
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, _) {
        this.setState(Object.assign({}, this.state, {value: null}));
    }

    data_to_excel(file_name, sheet_title, sheet_body) {

        let columnWidths = [];
        for (let i = 0; i < sheet_title.length; i++) columnWidths.push(10);

        let option = {
            fileName: file_name,
            datas: [{
                sheetData: sheet_body,
                sheetHeader: sheet_title,
                columnWidths: columnWidths,
            }]
        };

        let excel_obj = new ExportJsonExcel(option);
        excel_obj.saveExcel();
    }

    get_sheet_data(results) {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let columns = tool.getAttribute(spacePublic, 'columns');
        if (!columns) columns = [];

        let sheet_title = {};


        // 得到渲染过的行
        let sheet_body = results.map(line => {
            let newLine = {};
            for (let columnKey in columns) {
                if (!columns.hasOwnProperty(columnKey)) continue;
                let column = columns[columnKey];
                let value = line[columnKey];
                let type = column.type;
                if (type === 'imgPath') {
                    value = value ? '图片类型的数据' : '图片类型空数据';
                } else {
                    value = getColumnRender({
                        value: value,
                        record: line,

                        spacePublicPath: spacePublicPath,
                        spacePublic: spacePublic,
                        columnsInfo: null,
                        columnInfo: null,
                        columnKey: columnKey,
                    });
                }
                newLine[column.title] = value;
                sheet_title[column.title] = null;
            }
            return newLine;

        });


        //取出列名
        sheet_title = Object.keys(sheet_title);

        return {
            sheet_title: sheet_title,
            sheet_body: sheet_body,
        }
    }

    download() {
        let value = this.state.value;

        //空检测
        if (!value) {
            message.warning('输入下载数量!');
            return null;
        }

        // 数字检测
        value = parseFloat(value).toString();
        if (value === 'NaN') {
            message.warning('下载量必须是个数字!');
            return null;
        }

        // 0检测
        if (value === '0') {
            message.warning(`下载量不可以为0`);
            return null;
        }

        // 最高下载量检测
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let count = tool.getAttribute(spacePrivate, ['responseData', 'count',]);
        if (!count) count = 0;

        if (value > count) {
            message.warning(`最高下载量:${count}条`);
            return null;
        }


        message.info('请稍后!');
        this.setState(Object.assign({}, this.state, {down_text: <SyncOutlined spin/>}));

        //准备数据
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let path = tool.getAttribute(spacePublic, 'path');


        let file_name = `数据下载__${value}条`;
        // 获取get参数
        let paramUrl = {
            page_size: value,
        };
        let fatherRecord = tool.getAttribute(this, ['props', 'param', 'fatherRecord',]);
        if (fatherRecord) paramUrl['father_id'] = fatherRecord.id;


        let url = tool.get_url(path, false, paramUrl);

        //请求
        let args = {
            url: url,
            success: data => {
                let results = data.results;
                let sheet_data = this.get_sheet_data(results);
                let sheet_title = sheet_data.sheet_title;
                let sheet_body = sheet_data.sheet_body;

                this.data_to_excel(file_name, sheet_title, sheet_body);
                this.setState(Object.assign({}, this.state, {
                    down_text: '点击下载',
                }));
                message.success('下载成功!');
            },
            error: data => {
                tool.show_error(data);
            }
        };
        tool.get(args);
    }

    render() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let disableDownload = tool.getAttribute(spacePublic, 'disableDownload');
        if (disableDownload) return null;


        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let count = tool.getAttribute(spacePrivate, ['responseData', 'count',]);
        if (!count) count = 0;
        let info = `共${count}条数据`;


        return (
            <div style={{display: 'inline-block', verticalAlign: 'super', marginLeft: '5px',}}><Popover content={info}>
                <Input
                    addonBefore={"下载数量"}
                    addonAfter={<div
                        style={{width: '80px', height: '100%',}}
                        onClick={() => this.download()}
                    >{this.state.down_text}</div>}
                    style={{width: '250px',}}
                    onChange={event => this.setState(Object.assign({}, this.state, {value: event.target.value}))}
                    placeholder={info}
                    value={this.state.value}
                />
            </Popover></div>);
    }
}

function state_to_download(state) {

    return {};
}

function download_to_state(_) {
    return {};
}

let Download = connect(state_to_download, download_to_state)(DownloadUnConnected);

export {Download}
