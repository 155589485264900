function userInfo(param) {

    let value = param.value;
    if (value === undefined || value === null) {
        value = null;
    } else {
        // value = value.toString();
        value = `${value.name}(${value.username})`;
    }
    return value;
}

export {userInfo};

