import {tool} from "../../tools/tools";

function bool(param) {

    let textBool = tool.getAttribute(param, ['columnInfo', 'textBool',]);
    if (!textBool) return '定义textBool';

    let value = tool.getAttribute(param, ['value',]);
    if (value === true) return textBool[0];
    if (value === false) return textBool[1];
    return null;
}

export {bool};

