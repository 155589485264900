import React from 'react';
import {connect} from 'react-redux';
import {PieChartOutlined} from "@ant-design/icons";
import {tool} from "../../../tools/tools";

import './count.css';
import {Tooltip} from "antd";
import Text from "antd/es/typography/Text";


//数量组件 显示数据量
class CountUnConnected extends React.Component {
    isShow() {
        let param = tool.getAttribute(this, ['props', 'param',])
        let spacePublicPath = tool.getAttribute(param, ['spacePublicPath',])
        let spacePrivatePath = tool.getAttribute(param, ['spacePrivatePath',])
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let count = tool.getAttribute(spacePrivate, ['responseData', 'count',],);
        let showCount = tool.getAttribute(spacePublic, 'showCount', true,);
        let data;
        data = {
            showCount: showCount,
            count: count,
        }
        return data;
    }

    render() {
        let data = this.isShow()
        if (!data['showCount']) return null;
        let count = data['count'];
        return (<div className={'count_count'}>
            <Tooltip placement="leftTop" title={`当前${count}条数据`}>
                <PieChartOutlined/>
                <Text className={'count_text'}>{data['count']}</Text>
            </Tooltip>
        </div>)
    }
}

function state_to_count(state) {

    return {};
}

function count_to_state(_) {
    return {};
}

let Count = connect(state_to_count, count_to_state)(CountUnConnected);

export {Count}
