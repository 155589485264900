import {helpArray} from "./help-array";
import {bool} from "./bool";
import {char} from "./char";
import {money} from "./money";
import {map} from "./map";
import {imgPath} from "./img-path";
import {Video} from "./video";
import {label} from "./label";
import {file} from "./file";
import {render} from "./render";
import {chinaTime} from "./china-time";
import {yearTime} from "./year-time";
import {progress} from "./progress";
import {link} from "./link";
import {utcTime} from "./utc-time";
import {userInfo} from "./user-info";
import {tool} from "../../tools/tools";


let mapping = {
        // char: param => (param.value ? param.value.toString() : null),
        emptyArray: _ => null,
        helpArray: param => helpArray(param),
        bool: param => bool(param),
        char: param => char(param),
        money: param => money(param),
        chinaTime: param => chinaTime(param),
        yearTime: param => yearTime(param),
        render: param => render(param),
        map: param => map(param),
        imgPath: param => imgPath(param),
        imgShow: param => imgPath(param),
        imgHead: param => imgPath(param),
        video: param => Video(param),
        label: param => label(param),
        file: param => file(param),
        progress: param => progress(param),
        link: param => link(param),
        utcTime: param => utcTime(param),
        userInfo: param => userInfo(param),
    }
;


function getColumnsType(param) {
    let columnInfo = param.columnInfo;
    if (columnInfo && columnInfo.funType) return columnInfo.funType(param);
    if (columnInfo && columnInfo.type) return columnInfo.type;
    if (columnInfo && columnInfo.render) return 'render';
    let value = param.value;

    //help数组[{id:1,help:'xxx',},{id:2,help:'yyy',},]
    let isHelpArray = () => {
        let isArray = Array.isArray(value);
        if (!isArray) return false;

        if (value.length < 1) return 'emptyArray';

        let first = value[0];
        if (!first) return false;
        if (first.id && first.help) return 'helpArray';

        return false;

    };

    for (let func of [isHelpArray,]) {
        let type = func();
        if (type) return type;
    }

    return false;
}

// 是否被右键选中
function isChoice(param) {
    let rightClick = tool.getStateSpace(['rightClick']);
    let isShow = tool.getAttribute(rightClick, 'show');
    if (!isShow) return false;


    let rightId = tool.getAttribute(rightClick, ['record', 'id',]);
    let lineId = tool.getAttribute(param, ['record', 'id',],);
    if (rightId !== lineId) return false;

    let rightSpacePublicPath = tool.getAttribute(rightClick, ['spacePublicPath',]);
    let paramSpacePublicPath = tool.getAttribute(param, ['spacePublicPath',]);
    return rightSpacePublicPath.toString() === paramSpacePublicPath.toString();


}

function getColumnRender(param) {
    let columnType = getColumnsType(param);
    let render = mapping[columnType];
    if (!render) {
        columnType = 'char';
        render = mapping[columnType];
    }
    let result = render(param);
    let choice = isChoice(param);
    if (choice) {
        result = <div style={{color: 'red',}}>{result}</div>;
    }

    return result;
}

export {getColumnRender};

