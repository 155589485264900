import {tool} from "../../tools/tools";

function map(param) {
    let mapChoices = tool.getAttribute(param, ['columnInfo', 'map',]);
    if (!mapChoices) return '定义map{map:{1:xxx, 2:yyy}}';

    let value = tool.getAttribute(param, ['value',]);
    let mapValue = mapChoices[value];

    if (mapValue === undefined) return value;
    return mapValue;
}

export {map};

