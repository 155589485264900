import React from 'react';
import {Menu, Layout, Button, Modal, Input, Avatar, Affix,} from 'antd';
import {RootTable} from "../power-table/power-table";
import {CheckoutPage} from "./checkout_page/checkout_page";

import {connect} from 'react-redux';
import {tool} from "../tools/tools";
import {setting} from "../../setting";
import './layout.css';
import file from './1.jpeg';
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import Cropper from "react-cropper";
import $ from "jquery";

let SubMenu = Menu.SubMenu;
const {Header, Content, Sider, Footer,} = Layout;

function clearAuthenticate() {
    document.cookie = "jwt_token='';Path=/";
    tool.setStateSpace(['setting'], setting);
}

//资源菜单栏
class ComponentLayout extends React.Component {

    constructor(props) {
        super(props);
        // this.updateTableData = this.updateTableData.bind(this);
        this.onClickMenu = this.onClickMenu.bind(this);
        this.getMenus = this.getMenus.bind(this);
        this.getContent = this.getContent.bind(this);
        this.getLoginOut = this.getLoginOut.bind(this);
        this.getChoiceButton = this.getChoiceButton.bind(this);
        this.fileChange = this.fileChange.bind(this);
        this.state = {
            // file: null,
            fileName: '默认.jpg',
            src: null,
        }
        this.getMenuKey = lineInfo => lineInfo.name + lineInfo.path;
    }

    onClickMenu(lineSpacePath, isPath, paramFather = false) {
        // lineSpacePath 选中的菜单空间
        // isPath 是否资源菜单
        // paramFather 非手动调用参数

        //选中的资源表格空间里面的表格数据加载状态(stateTableOnload)修改为true
        let spacePrivateKey = `spacePrivate__root`; //根表格的私有空间key
        let spacePrivatePath = lineSpacePath.concat(['table', spacePrivateKey,]);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        spacePrivate = Object.assign({}, spacePrivate, {
            stateTableOnload: true,
        });
        tool.setStateSpace(spacePrivatePath, spacePrivate);

        //更新选中的资源
        let lineInfo = tool.getStateSpace(lineSpacePath);
        let menuKey = this.getMenuKey(lineInfo);

        let setting = tool.getStateSpace(['setting',]);
        let selectedMenu = tool.getAttribute(setting, ['selectedMenu',]);
        if (isPath) {
            selectedMenu = Object.assign({}, selectedMenu, {
                selectedKey: menuKey,
                selectedSpacePath: lineSpacePath,
                paramFather: paramFather,
            });
        } else {
            selectedMenu = Object.assign({}, selectedMenu, {
                selectedGroup: menuKey,
                selectedGroupSpacePath: lineSpacePath,
            });
        }

        setting = Object.assign({}, setting, {
            selectedMenu: selectedMenu,
            onClickMenu: this.onClickMenu,
        });

        tool.setStateSpace(['setting',], setting);
    }

    getMenus(spacePath) {
        if (!spacePath) {
            let rolePath = ['setting', 'authenticate', 'role',];
            let role = tool.getStateSpace(rolePath);
            if (!role) return null;

            spacePath = ['setting', role,];
        }

        let menuInfo = tool.getStateSpace(spacePath);
        // console.log('menuINfo', menuInfo)
        let getMenu = (lineSpacePath) => {
            let lineInfo = tool.getStateSpace(lineSpacePath);
            let react;

            let sub = tool.getAttribute(lineInfo, 'sub',);
            let name = tool.getAttribute(lineInfo, 'name',);
            let key = this.getMenuKey(lineInfo);

            if (sub) {
                react = <SubMenu key={key} title={name} onClick={() => this.onClickMenu(lineSpacePath)}>
                    {this.getMenus(lineSpacePath.concat(['sub']))}
                </SubMenu>;
            } else {
                react = <Menu.Item key={key} onClick={() => this.onClickMenu(lineSpacePath, true,)}>{name}</Menu.Item>
            }
            return react;
        };


        let menuReact = [];
        for (let lineKey in menuInfo) {
            if (!menuInfo.hasOwnProperty(lineKey)) continue;
            if (lineKey.slice(0, 6) !== 'menu__') continue;
            menuReact.push(getMenu(spacePath.concat([lineKey])));
        }

        return menuReact;
    }

    clearAuthenticate() {
        // document.cookie = "jwt_token='';Path=/";
        // tool.setStateSpace(['setting'], setting);
        clearAuthenticate();
    }

    getLoginOut() {
        let authenticatePath = ['setting', 'authenticate',];
        let authentication = tool.getStateSpace(authenticatePath);
        let username = tool.getAttribute(authentication, 'username');
        let name = tool.getAttribute(authentication, 'name');

        let onClick = () => {
            // document.cookie = "jwt_token='';Path=/";
            // tool.setStateSpace(['setting'], setting);
            this.clearAuthenticate();
            tool.show_info('已退出系统');
        };


        return (<div className={'login-out'}>
            <div className={'login-out-button'}><Button type={'link'} onClick={onClick}>退出</Button></div>
            <div className={'username-info'}>{name}({username})已登录</div>
        </div>);
    }

    getChangePassword() {
        let setPasswordPath = ['setting', 'setPassword',];
        let stateShowPath = ['setting', 'setPassword', 'stateShow',];
        let oldPasswordPath = ['setting', 'setPassword', 'oldPassword',];
        let newPasswordPath = ['setting', 'setPassword', 'newPassword',];

        let closeAndClear = () => {
            let setPassword = tool.getStateSpace(setPasswordPath);
            setPassword = Object.assign({}, setPassword, {
                stateShow: false,
                oldPassword: '',
                newPassword: '',
            });
            tool.setStateSpace(setPasswordPath, setPassword);
        };
        let handleOk = () => {
            let oldPassword = tool.getStateSpace(oldPasswordPath);
            let newPassword = tool.getStateSpace(newPasswordPath);
            let data = {
                old_password: oldPassword,
                new_password: newPassword,
            };
            let setPath = tool.getStateSpace(['setting', 'authenticate', 'setPath',]);
            // console.log('set---', setPath)
            let param_request = {
                path: setPath,
                data: data,
                success: data => {
                    tool.show_success(['修改成功！']);
                    closeAndClear();

                    // 清除登录信息
                    this.clearAuthenticate();

                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.post(param_request);
        };


        // 显示状态
        let stateShow = tool.getStateSpace(stateShowPath);

        // 输入框内容
        let oldPassword = tool.getStateSpace(oldPasswordPath);
        if (oldPassword === undefined) oldPassword = '';
        let newPassword = tool.getStateSpace(newPasswordPath);
        if (newPassword === undefined) newPassword = '';
        return (<div className={'login-out'}>
            <div className={'login-out-button'}>
                <Button type={'link'} onClick={() => tool.setStateSpace(stateShowPath, true)}>
                    修改密码
                </Button></div>
            <Modal title="修改密码"
                   visible={stateShow}
                   onOk={handleOk}
                   onCancel={() => closeAndClear()}
                   okText={'提交'}
                   cancelText={'关闭'}
                   width={'300px'}
            >
                <Input.Password
                    placeholder="输入旧密码"
                    addonBefore={'旧密码'}
                    style={{width: '80%',}}
                    value={oldPassword}
                    // value={6}
                    onChange={event => {
                        let value = event.target.value;
                        tool.setStateSpace(oldPasswordPath, value);
                    }}
                />
                <br/><br/>
                <Input.Password
                    placeholder="输入新密码"
                    addonBefore={'新密码'}
                    style={{width: '80%',}}
                    value={newPassword}
                    onChange={event => {
                        let value = event.target.value;
                        tool.setStateSpace(newPasswordPath, value);
                    }}
                />
            </Modal>
        </div>);
    }

    getContent() {
        let lineSpacePath = ['setting', 'selectedMenu', 'selectedSpacePath',];
        let lineSpace = tool.getStateSpace(lineSpacePath);
        let react = tool.getAttribute(lineSpace, 'react');
        if (react) return react();
        if (!lineSpace) return null;
        return <RootTable/>;
    }

    fileChange = event => {
        const MAX_FILE_SIZE = 5 * 1024 * 1024 // 文件最大限制为5M 裁剪
        let files = event.target.files;
        let file = null;
        if (files.length > 0) file = event.target.files[0];
        if (!file) return null;
        if (file.size > MAX_FILE_SIZE) {
            tool.show_info('文件过大');
            return null;
        }

        const fileReader = new FileReader();
        fileReader.onload = event => {
            const dataURL = event.target.result
            this.setState({
                src: dataURL,
                fileName: file.name,
            })
        };
        fileReader.readAsDataURL(file);
    }

    getChoiceButton() {
        if (this.state.isShow) return null;

        let inputId = Math.ceil(Math.random() * 10).toString();

        return (
            <div className={'fields-img-path-button'}>
                <input
                    id={inputId}
                    className={'file-input'}
                    type={'file'}
                    onChange={this.fileChange}
                    accept={"image/gif, image/jpeg,image/jpg,image/png,image/bmp,"}
                />
                <div className={'fields-img-path-button-one'}>
                    <Button
                        type={'primary'}
                        onClick={() => $(`#${inputId}`).click()}
                    >
                        点击换图
                    </Button>
                </div>
            </div>
        );
    }

    getCover() {
        let coverPath = ['setting', 'authenticate', 'responseData', 'user_info', 'cover',];
        let cover = tool.getStateSpace(coverPath);

        let setCoverPath = ['setting', 'setCover',];
        let stateShowPath = ['setting', 'setCover', 'stateShow',];

        let closeAndClear = () => {
            let setCover = tool.getStateSpace(setCoverPath);
            setCover = Object.assign({}, setCover, {
                stateShow: false,
                cover: '',
            });
            tool.setStateSpace(setCoverPath, setCover);
        };

        let handleOk = () => {
            let canvas;
            canvas = this.cropper['getCroppedCanvas']();
            if (!canvas) {
                tool.show_info('没有内容.')
                return;
            }
            canvas.toBlob(async blob => {
                let file = new window.File([blob], this.state.fileName, {type: 'image/jpeg'});
                let data = new FormData();
                data.append('cover', file);
                let setPath = tool.getStateSpace(['setting', 'authenticate', 'setPath',]);
                let param_request = {
                    path: setPath,
                    data: data,
                    contentType: false,
                    processData: false,
                    success: data => {
                        tool.setStateSpace(coverPath, data['src']);
                        tool.show_success(['修改成功！']);
                        closeAndClear();
                    },
                    error: data => {
                        tool.show_error(data);
                    },
                };
                tool.post(param_request);
            })
        };

        // 显示状态
        let stateShow = tool.getStateSpace(stateShowPath);
        if (cover) cover = tool.path_join(cover);

        // 计算src
        let src = this.state.src;
        if (!src) src = cover;


        return <div className={'login-cover'}>

            <div onClick={() => tool.setStateSpace(stateShowPath, true)}>
                <Avatar shape="square" src={cover} icon={<UserOutlined/>}/>
            </div>

            <Modal title="换个头像"
                   visible={stateShow}
                   onOk={handleOk}
                   onCancel={() => tool.setStateSpace(stateShowPath, false)}
                   okText={'提交'}
                   cancelText={'关闭'}
                   width={'300px'}
            >
                <div className="cropper-container">
                    <Cropper
                        src={src}
                        ref={cropper => this.cropper = cropper}
                        viewMode={1}
                        zoomable={false}
                        aspectRatio={1}
                    />
                    {this.getChoiceButton()}
                </div>
            </Modal>

        </div>;
    }

    getAffixMenuReact() {
        let rolePath = ['setting', 'authenticate', 'role',];
        let role = tool.getStateSpace(rolePath);
        let spacePath = ['setting', role,];
        let menuReact = this.getMenus(spacePath);

        let stateAffixMenuGlobal;
        stateAffixMenuGlobal = tool.getStateSpace(['setting', 'stateAffixMenu',]);
        let stateAffixMenu = stateAffixMenuGlobal;
        let stateAffixMenuRole = tool.getStateSpace(spacePath.concat(['stateAffixMenu']));
        if (stateAffixMenuRole !== undefined) stateAffixMenu = stateAffixMenuRole;
        if (stateAffixMenu) {
            return <Affix>
                <Menu
                    mode="horizontal"
                    // mode="inline"
                    style={{
                        height: '100%',
                        borderRight: 0,
                        'boxShadow': '2px 2px 10px #909090',
                    }}
                    selectedKeys={[tool.getStateSpace(['setting', 'selectedMenu', 'selectedKey',],)]}
                >
                    {menuReact}
                </Menu>
            </Affix>
        } else {
            return <Sider
                width={200}
                className="site-layout-background"
                style={{
                    height: '100%',
                    marginLeft: '20px',
                    marginTop: '26px',
                    marginBottom: '100px',
                }}
            >
                <Menu
                    mode="inline"
                    style={{
                        height: '100%',
                        borderRight: 0,
                        'boxShadow': '2px 2px 10px #909090',
                    }}
                    selectedKeys={[tool.getStateSpace(['setting', 'selectedMenu', 'selectedKey',],)]}
                >
                    {menuReact}
                </Menu>
            </Sider>
        }
    }


    render() {
        let rolePath = ['setting', 'authenticate', 'role',];
        let role = tool.getStateSpace(rolePath);
        if (!role) return null;

        let spacePath = ['setting', role,];
        let menuInfo = tool.getStateSpace(spacePath);

        // 向第3方页面跳转
        let path = tool.getAttribute(menuInfo, 'path');
        if (path) {
            path = tool.path_join(path);
            window.location.href = path;
        }

        // 向扩展页面跳转 共享store
        let nowPage = tool.getAttribute(menuInfo, 'nowPage');
        if (nowPage) {
            window.history.pushState({}, 'xxx', '?nowPage=student');
            return tool.getStateSpace(['setting', 'pageMap', nowPage, 'react',]);
        }


        let content = this.getContent();

        let systemTitle = tool.getStateSpace(['setting', role, 'systemTitle',]);

        let loginOut = this.getLoginOut();
        let changePassword = this.getChangePassword();
        let cover = this.getCover();
        let footer = tool.getStateSpace(['setting', role, 'footer',]);
        let affixMenuReact = this.getAffixMenuReact();

        return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundImage: `url(${file})`,
                    position: 'absolute',
                }}
            >
                <Layout
                    style={{
                        backgroundImage: `url(${file})`,
                    }}
                >
                    <Header className="header">
                        <div className="logo">{systemTitle}</div>
                        {cover}
                        {changePassword}
                        {loginOut}
                        <CheckoutPage/>
                    </Header>
                    <Layout
                        style={{
                            backgroundColor: 'rgba(255,255,255,0)',
                        }}
                    >
                        {affixMenuReact}
                        <Layout style={{
                            padding: '0 24px 24px',
                            backgroundColor: 'rgba(255,255,255,0)',
                        }}>
                            <Content
                                className="site-layout-background"
                                style={{
                                    padding: 24,
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >
                                {content}
                            </Content>
                        </Layout>
                    </Layout>
                    <Footer
                        style={{
                            textAlign: 'center',
                            position: 'fixed',
                            width: '100%',
                            bottom: '0',
                            backgroundColor: 'rgba(200,200,200,255)',
                        }}>{footer}
                    </Footer>
                </Layout>
            </div>
        );
    }
}

function mapStateRoLayout(state) {
    return {
        selectedKey: state.setting.selectedMenu.selectedKey,
        setPassword: state.setting.setPassword,
        setCover: state.setting.setCover,
        // stateShow: state.setting.setCover.stateShow,
        setting: state.setting,
    }
}

ComponentLayout = connect(mapStateRoLayout)(ComponentLayout);

export {ComponentLayout, clearAuthenticate,}
