import {Input, Button, Modal} from 'antd';
import React from 'react';

import {tool} from "../tools/tools";

import file from './1.jpeg'
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import KeyOutlined from "@ant-design/icons/lib/icons/KeyOutlined";
import {Text} from "../../setting";
import {connect} from "react-redux";


//选择角色
class ChoiceRole extends React.Component {
    onChoice(code) {
        let authenticatePath = ['setting', 'authenticate',];
        let authenticate = tool.getStateSpace(authenticatePath);

        authenticate = Object.assign({}, authenticate, {
            role: code,//当前使用的角色
            choiceRole: false,//是否调起角色选择界面
        });
        tool.setStateSpace(authenticatePath, authenticate);
        let roleTitle = tool.getStateSpace(['setting', code, 'webTitle',]);
        tool.setTitle(roleTitle);
    }


    render() {
        let choiceRolePath = ['setting', 'authenticate', 'choiceRole',];
        let choiceRole = tool.getStateSpace(choiceRolePath);

        let roleInfoPath = ['setting', 'authenticate', 'roleInfo',];
        let roleInfo = tool.getStateSpace(roleInfoPath);
        if (!roleInfo) roleInfo = [];


        let setting = tool.getStateSpace(['setting']);

        let roleButtons = [];
        for (const role of roleInfo) {
            let code = role.code;
            if (!setting[code]) continue;
            let role_name = role.role_name;
            roleButtons.push(
                <div key={code}>
                    <Button
                        onClick={() => this.onChoice(code)}
                    >
                        {role_name}
                    </Button>
                    <br/><br/>
                </div>
            );
        }
        return (
            <Modal
                title="选择角色"
                visible={choiceRole}
                onOk={this.onOk}
                onCancel={this.onCancel}
                footer={null}
            >
                {roleButtons}
            </Modal>
        )
    }
}

function mapStateToChoiceRole(state) {
    return {
        authenticate: state.setting.authenticate,
    }
}

ChoiceRole = connect(mapStateToChoiceRole)(ChoiceRole);

//通用登录界面
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.change = this.change.bind(this);
        this.submit = this.submit.bind(this);
        this.state = {
            username: '',
            password: '',
            remember: false,
        };
    }

    change(field, value) {
        let data = {};
        data[field] = value;
        this.setState(Object.assign({}, this.state, data));
    }

    componentDidMount() {
        let urlPath = tool.getStateSpace(['setting', 'authenticate', 'loginPath',]);
        let requestParam = {
            url: tool.get_url(urlPath),
            success: responseData => {
                if (responseData.state === 403) return null;

                let abstract_role_name = tool.getAttribute(responseData, ['abstract_role_name'],);
                let roleInfo = tool.getAttribute(responseData, ['roleInfo'],);
                roleInfo = tool.roleInfoHandler(roleInfo);

                if ((!abstract_role_name) && (!roleInfo)) {
                    tool.show_error("没有角色信息");
                    return null;
                }

                let authenticate = tool.getStateSpace(['setting', 'authenticate',]);

                // 当前使用的角色
                let nowRole = null;
                let choiceRole = false;

                if (abstract_role_name && (!roleInfo)) {
                    nowRole = abstract_role_name;
                } else {
                    if (roleInfo.length === 1) {
                        nowRole = roleInfo[0].code
                    } else {
                        choiceRole = true;
                    }
                }


                authenticate = Object.assign({}, authenticate, {
                    responseData: responseData,
                    jwtToken: tool.getAttribute(responseData, ['jwt_token'],),
                    username: tool.getAttribute(responseData, ['username'],),
                    name: tool.getAttribute(responseData, ['name'],),
                    // role: tool.getAttribute(responseData, ['abstract_role_name'],),
                    role: nowRole,//当前使用的角色
                    roleInfo: roleInfo, // 角色信息列表
                    choiceRole: choiceRole,//是否调起角色选择界面

                    headers: {authorization: tool.getAttribute(responseData, ['jwt_token'],),},
                });
                let roleTitle = tool.getStateSpace(['setting', authenticate.role, 'webTitle',]);
                tool.setTitle(roleTitle);

                tool.setStateSpace(['setting', 'authenticate',], authenticate);
            },
        };
        tool.get(requestParam);
    }

    submit() {
        let urlPath = tool.getStateSpace(['setting', 'authenticate', 'loginPath',]);
        let requestParam = {
            url: tool.get_url(urlPath),
            data: this.state,
            success: responseData => {
                let abstract_role_name = tool.getAttribute(responseData, ['abstract_role_name'],);
                let roleInfo = tool.getAttribute(responseData, ['roleInfo'],);

                // 旧版兼容
                if (!roleInfo) {
                    let abstract_role_name = tool.getAttribute(responseData, ['abstract_role_name'],);
                    roleInfo = [{code: abstract_role_name, role_name: "未知"}]
                }
                roleInfo = tool.roleInfoHandler(roleInfo);
                if ((!abstract_role_name) && (!roleInfo)) {
                    tool.show_error("没有角色信息");
                    return null;
                }

                let authenticate = tool.getStateSpace(['setting', 'authenticate',]);

                // 当前使用的角色
                let nowRole = null;
                let choiceRole = false;

                if (abstract_role_name && (!roleInfo)) {
                    nowRole = abstract_role_name;
                } else {
                    if (roleInfo.length === 1) {
                        nowRole = roleInfo[0].code
                    } else {
                        choiceRole = true;
                    }
                }

                authenticate = Object.assign({}, authenticate, {
                    responseData: responseData,
                    jwtToken: tool.getAttribute(responseData, ['jwt_token'],),
                    username: tool.getAttribute(responseData, ['username'],),
                    name: tool.getAttribute(responseData, ['name'],),
                    role: nowRole,//当前使用的角色
                    roleInfo: roleInfo, // 角色信息列表
                    choiceRole: choiceRole,//是否调起角色选择界面
                    headers: {authorization: tool.getAttribute(responseData, ['jwt_token'],),},
                });
                let roleTitle = tool.getStateSpace(['setting', authenticate.role, 'webTitle',]);
                tool.setTitle(roleTitle);

                tool.setStateSpace(['setting', 'authenticate',], authenticate);
            },
            error: error => {
                tool.show_error(error);
            },
        };
        tool.post(requestParam);
    }

    render() {
        let div_0 = {
            width: '100%',
            height: '100%',
            backgroundImage: `url(${file})`,
            backgroundSize: '100% 100%',
            position: 'absolute',
        };
        let div_1 = {
            width: '400px',
            height: '350px',
            border: 'rgba(130, 50, 21, 0.16) 1px solid',
            marginLeft: '50%',
            transform: 'translate(-50%,50%)',
            backgroundColor: "#FFFFFF",

        };
        let div_2 = {
            width: '300px',
            // border: 'red 1px solid',
            marginLeft: '50%',
            //
            transform: 'translate(-50%,10%)',

        };
        let input_height = 40;
        let button_style = {
            width: '100%',
            height: input_height + 'px',
        };
        let input_style = {
            height: input_height + 'px',
        };

        // 计算logo
        let logo = 'LOGO';
        let logoPath = tool.getStateSpace(['setting', 'authenticate', 'logoImage',]);
        if (logoPath) {
            logo = <div style={{
                // border: 'red solid 1px',
                transform: 'translate(-45%,0%)',
                width: '90%',
                // height:'60%',
            }}>
                <img src={logoPath} alt={'LOGO'} width={'100%'} height={'auto'}/>
            </div>;
        }

        return (
            <div>
                <ChoiceRole/>
                <div style={div_0}>&nbsp;</div>
                <div style={div_1}>
                    <div style={div_2}>
                        <div style={{marginLeft: '45%',}}>{logo}</div>
                        <br/><br/>
                        <Input
                            style={input_style}
                            onChange={event => this.change('username', event.target.value)}
                            value={this.state.username}
                            placeholder={Text("输入你的系统帐号")}
                            prefix={<UserOutlined className="site-form-item-icon"/>}
                            onPressEnter={() => this.submit()}
                        />
                        <br/><br/>
                        <Input.Password
                            // placeholder="input password"
                            style={input_style}
                            onChange={event => this.change('password', event.target.value)}
                            value={this.state.password}
                            type="password"
                            placeholder={Text("输入你的密码")}
                            prefix={<KeyOutlined className="site-form-item-icon"/>}
                            onPressEnter={() => this.submit()}
                        />
                        {/*<br/><br/>*/}
                        <br/><br/>
                        <Button type="primary" style={button_style} onClick={() => this.submit()}>{Text("登录")}</Button>
                    </div>
                </div>
            </div>
        )
    }
}


export {Login, ChoiceRole,}
