import React from "react";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './utc-time.css';


//UtcTime类型的字段
class FieldDetailUtcTime extends React.Component {

    getContent() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let recordId = tool.getAttribute(this, ['props', 'param', 'record', 'id',]);

        let columnsDetailLinePath = spacePublicPath.concat(['columnsDetailLine']);
        let columnsDetailLineSpace = tool.getStateSpace(columnsDetailLinePath);

        let column = tool.getAttribute(columnsDetailLineSpace, columnKey);
        let title = tool.getAttribute(column, 'title');

        // 获取值
        let dataKey = `detail__${recordId}`;
        let paramModalPath = ['infoModal', 'paramModal',];
        let paramModal = tool.getStateSpace(paramModalPath);
        paramModal = Object.assign({}, paramModal);
        let data = paramModal[dataKey];
        if (!data) data = {};


        let value = data[columnKey];
        if (value === null || value === undefined) {
            value = null;
        } else {
            value = value.toString();
        }

        // 显示为中国时间
        value = tool.utc_to_china(value);
        // let date_obj = new Date(value);
        // let year = date_obj.getFullYear();
        // let month = date_obj.getMonth() + 1;
        // let day = date_obj.getDate();
        //
        // let hours = date_obj.getHours();
        // let minutes = date_obj.getMinutes();
        // value = `${year}年${month}月${day}日 ${hours}点${minutes}分`;

        return (
            <div>
                <div className={'detail-title'}>
                    {title}
                </div>
                {value}
            </div>
        )
    }

    render() {
        let content = this.getContent();
        return (
            <div>
                {content}
            </div>
        )
    }
}

function mapStateToUpdate(state) {
    return {
        infoModal: state.infoModal,
    }
}

FieldDetailUtcTime = connect(mapStateToUpdate)(FieldDetailUtcTime);

export {FieldDetailUtcTime,};
