function yearTime(param) {

    let value = param.value;
    if (value === undefined || value === null) {
        value = null;
    } else {
        value = value.toString();
    }

    // 显示为中国时间
    let date_obj = new Date(value);
    let year = date_obj.getFullYear();
    // let month = date_obj.getMonth() + 1;
    // let day = date_obj.getDate();
    //
    // let hours = date_obj.getHours();
    // let minutes = date_obj.getMinutes();
    value = `${year}年`;


    return value;
}

export {yearTime};

