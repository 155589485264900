import React from 'react';
import {connect} from 'react-redux';
import {tool} from "../../../tools/tools";
import './jump.css';


//跳转组件
class JumpUnConnected extends React.Component {

    render() {
        let spacePublicPath = tool.getAttribute(this.props.param, 'spacePublicPath',);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let jumps = tool.getAttribute(spacePublic, 'jumps');
        if (!jumps) return null;

        let div = [];
        for (const jump of jumps) {
            let link = jump[0];
            link = tool.path_join(link);
            let text = jump[1];
            div.push(<a
                href={link}
                key={link + text}
                target="_blank"
                rel="noreferrer"
                className={'jump__link'}
            >{text}</a>);
        }
        return div;
    }
}

function state_to_jump(state) {

    return {};
}

function jump_to_state(_) {
    return {};
}

let Jump = connect(state_to_jump, jump_to_state)(JumpUnConnected);

export {Jump}
