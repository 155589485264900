import React from "react";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './file.css';


//file类型的字段
class FieldDetailFile extends React.Component {

    getContent() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let recordId = tool.getAttribute(this, ['props', 'param', 'record', 'id',]);

        let columnsDetailLinePath = spacePublicPath.concat(['columnsDetailLine']);
        let columnsDetailLineSpace = tool.getStateSpace(columnsDetailLinePath);

        let column = tool.getAttribute(columnsDetailLineSpace, columnKey);
        let title = tool.getAttribute(column, 'title');

        // 获取值
        let dataKey = `detail__${recordId}`;
        let paramModalPath = ['infoModal', 'paramModal',];
        let paramModal = tool.getStateSpace(paramModalPath);
        paramModal = Object.assign({}, paramModal);
        let data = paramModal[dataKey];
        if (!data) data = {};

        let value = data[columnKey];
        if (value) {
            // 链接文字
            let textLink = tool.getAttribute(column, ['textLink',]);
            if (!textLink) textLink = '链接';

            value = tool.get_url(value);
            value = <a href={value} target="_blank" rel="noreferrer">{textLink}</a>
        }

        return (
            <div>
                <div className={'detail-title'}>
                    {title}
                </div>
                {value}
            </div>
        )
    }

    render() {
        let content = this.getContent();
        return (
            <div>
                {content}
            </div>
        )
    }
}

function mapStateToUpdate(state) {
    return {
        infoModal: state.infoModal,
    }
}

FieldDetailFile = connect(mapStateToUpdate)(FieldDetailFile);

export {FieldDetailFile,};
