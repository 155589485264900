import { fieldMapping, fieldDetailMapping } from "./public_setting";
import {SpikeLogin} from "./apps/spike20/login/spike_login";

const conf = [
  ["输入你的系统帐号", "Enter your system account number."],
  ["spike20系统后台", "spike20."],
  ["输入你的密码", "Enter your password."],
  ["登录", "Login"],
];

function language() {
  return 1;
}

function conf2mapping() {
  // 仅运行一次, 转化为符合js语法并可以哈希查询的格式
  let mapping = {};
  for (const line of conf) {
    let key = encodeURIComponent(line[0]);
    mapping[key] = line[1];
  }
  return mapping;
}

const mapping = conf2mapping();

function Text(text) {
  if (language() === 1) return text;
  let key = encodeURIComponent(text);
  if (mapping[key]) return mapping[key];
  // console.log('未翻译的汉语---', text);
  return text;
}

//艾克米克系统后台 打卡点页码百分比
let Spike20Footer = "©2021 Spike20 ";

let setting = {
  title: Text("spike20系统后台"),
  authenticate: {
    loginReact: <SpikeLogin/>,
    loginPath: "/programmer/login/",
    setPath: "/programmer/set_password/",
    headers: null,
    role: null,
    username: null,
    responseData: null,
    jwtToken: null,
    abstract_role_name: null,
    logoImage: "",
    english: false,
  },
  programmer: {
    webTitle: "spike20系统",
    systemTitle: "程序管理员数据控制台",
    footer: Spike20Footer,
    menu__account: {
      name: "帐号",
      sub: {
        menu__base_account: {
          name: "帐号一览",
          table: {
            path: "programmer/user/",
            columns: {
              name: { title: "名字" },
              username: { title: "系统帐号" },
              role: { title: "角色信息" },
              cover: { title: "头像", type: "imgHead" },
            },
            columnsUpdate: {
              name: { title: "名字" },
              username: { title: "系统帐号" },
              password: { title: "密码" },
              cover: { title: "头像", type: "imgHead" },
            },
            text__update_title: "修改这个系统账号",
            disableDownload: true,
          },
        },
        menu__roles: {
          name: "角色一览",
          table: {
            path: "programmer/role/",
            columns: {
              role_name: { title: "角色名" },
              code: { title: "系统识别名" },
            },
            disableDownload: true,
          },
        },
        menu__wechat_account: {
          name: "微信帐号",
          table: {
            path: "api_back/wechat/",
            columns: {
              avatarUrl: { title: "微信头像", type: "imgPath" },
              nickName: { title: "微信昵称" },
              roles: { title: "系统角色" },
              remark: { title: "备注" },
            },
            columnsDetailLine: {
              avatarUrl: { title: "微信头像", type: "imgShow" },
              created_at: { title: "创建于", type: "chinaTime" },
              phone: { title: "手机号" },
              address: { title: "地址" },
              openid: { title: "openid" },
              session_key: { title: "session_key" },
              allowed_sub_count: { title: "允许的副卡数量" },
              union_id: { title: "公众号ID" },
              roles: { title: "系统角色" },
              score: { title: "积分" },
              remark: { title: "备注" },
              nickName: { title: "微信昵称" },
              name: { title: "系统名称" },
              invitation_code: { title: "副卡邀请码" },
              gender: {
                title: "性别",
                type: "map",
                map: {
                  0: "未知",
                  1: "男性",
                  2: "女性",
                },
              },
              country: { title: "国家" },
              province: { title: "省份" },
              city: { title: "城市" },
              language: {
                title: "语言",
                type: "map",
                map: {
                  en: "英文",
                  zh_CN: "简体中文",
                  zh_TW: "繁体中文",
                },
              },
            },
            columnsUpdate: {
              avatarUrl: { title: "微信头像" },
              phone: { title: "手机号" },
              address: { title: "地址" },
              openid: { title: "openid" },
              session_key: { title: "session_key" },
              union_id: { title: "公众号ID" },
              remark: { title: "备注" },
              nickName: { title: "微信昵称" },
              name: { title: "系统名称" },
              gender: {
                title: "性别",
                type: "singleSmallChoice",
                choices: [
                  { value: "0", help: "未知" },
                  { value: "1", help: "男性" },
                  { value: "2", help: "女性" },
                ],
              },
              country: { title: "国家" },
              province: { title: "省份" },
              city: { title: "城市" },
              language: {
                title: "语言",
                type: "singleSmallChoice",
                choices: [
                  { value: "en", help: "英文" },
                  { value: "zh_CN", help: "简体中文" },
                  { value: "zh_TW", help: "繁体中文" },
                ],
              },
              roles_id: {
                title: "系统角色",
                type: "multipleChoice",
                path: "/api_back/choices_role/",
              },
            },
            columnsUpdate_1: {
              target_wechat_id: {
                title: "把主卡身份转移给",
                type: "singSearchChoice",
                path: "api_back/choices_master_to/",
              },
            },
            columnsCreate: {
              avatarUrl: { title: "微信头像" },
              phone: { title: "手机号" },
              address: { title: "地址" },
              openid: { title: "openid" },
              session_key: { title: "session_key" },
              union_id: { title: "公众号ID" },
              remark: { title: "备注" },
              nickName: { title: "微信昵称" },
              name: { title: "系统名称" },
              gender: {
                title: "性别",
                type: "singleSmallChoice",
                choices: [
                  { value: 0, help: "未知" },
                  { value: 1, help: "男性" },
                  { value: 2, help: "女性" },
                ],
              },
              country: { title: "国家" },
              province: { title: "省份" },
              city: { title: "城市" },
              language: {
                title: "语言",
                type: "singleSmallChoice",
                choices: [
                  { value: "en", help: "英文" },
                  { value: "zh_CN", help: "简体中文" },
                  { value: "zh_TW", help: "繁体中文" },
                ],
              },
            },
            text__update_title: "修改这个微信账号",
            text__update_part_right: "换权",
            text__search_help: "通过昵称搜索",
            sub__sub_content: {
              columnTitle: "ta推荐了谁",
              path: "api_v1/sub_wechat/",
              columns: {
                avatarUrl: { title: "头像", type: "imgPath" },
                created_at: { title: "创建于", type: "chinaTime" },
                phone: { title: "手机号" },
                remark: { title: "备注" },
                nickName: { title: "昵称" },
              },
              columnsCreate: {
                wechat_obj: {
                  title: "选择微信账号",
                  type: "singSearchChoice",
                  path: "api_v1/choices_wechat/",
                },
              },
              text__create_button: "增加推荐",
              text__update_title: "修改这个系统账号",
              disableDownload: true,
            },
            sub__sub_vip: {
              columnTitle: "ta的会员",
              path: "programmer/sub_vip/",
              columns: {
                valid_to: { title: "有效期至", type: "utcTime" },
                vip_code: {
                  title: "会员类型",
                  type: "map",
                  map: {
                    1: "book talk",
                  },
                },
                name: { title: "会员名称" },
              },
              columnsUpdate: {
                valid_to: { title: "有效期至", type: "time" },
              },
              columnsCreate: {
                valid_to: { title: "有效期至", type: "time" },
                vip_obj: {
                  title: "会员类型",
                  type: "singSearchChoice",
                  path: "programmer/choices_vip/",
                },
              },
              disableDownload: true,
              disableCopy: true,
              disableCreate: true,
              text__create_button: "添加会员",
            },
            sub__sub_card: {
              columnTitle: "ta的副卡",
              path: "api_back/sub_card/",
              columns: {
                avatarUrl: { title: "头像", type: "imgPath" },
                created_at: { title: "创建于", type: "chinaTime" },
                phone: { title: "手机号" },
                remark: { title: "备注" },
                nickName: { title: "昵称" },
                roles: { title: "角色" },
              },
              columnsCreate: {
                wechat_id: {
                  title: "选择副卡",
                  type: "singSearchChoice",
                  path: "api_back/choices_card/",
                },
              },
              text__create_button: "增加副卡",
              disableCreate: true,
              disableDownload: true,
              disableCopy: true,
              text__delete: "解除副卡",
            },
            sub__sub_pay: {
              columnTitle: "支付记录",
              path: "api_back/sub_pay/",
              columns: {
                vip_code: {
                  title: "vip类型",
                  type: "map",
                  map: {
                    1: "book talk",
                  },
                },
                price: {
                  title: "vip价格",
                  type: "render",
                  render: (value) => {
                    if (!value) return value;
                    value = value / 100;
                    return value + "元";
                  },
                },
                name: { title: "vip名称" },
                total: {
                  title: "支付金额",
                  type: "render",
                  render: (value) => `${value / 100}元`,
                },
                out_trade_no: { title: "订单号" },
                state: {
                  title: "处理状态",
                  type: "map",
                  map: {
                    1: "未处理",
                    2: "已处理",
                  },
                },
                description: { title: "描述" },
              },
              disableDownload: true,
            },
            disableCopy: true,
            disableCreate: true,
          },
        },
      },
    },
    menu__conf: {
      name: "系统设置",
      sub: {
        menu__conf: {
          name: "系统设置",
          table: {
            path: "set_conf/set_conf/",
            columns: {
              name: { title: "配置项" },
              value: {
                title: "值",
                type: "render",
                render: (value, param) => {
                  let record = param.record;
                  let key = record.key;
                  if (key === "language") {
                    if (value === "1") return "简体中文";
                    if (value === "2") return "english";
                  }
                  return value;
                },
              },
              help_text: { title: "帮助" },
            },
            columnsDetailLine: {
              name: { title: "配置项" },
              value: { title: "值" },
              help_text: { title: "帮助" },
            },
            columnsUpdate: {
              name: { title: "配置项" },
              value: { title: "值" },
              help_text: { title: "帮助" },
            },
            columnsCreate: {
              key: { title: "计算机识别码" },
              name: { title: "配置项" },
              value: { title: "值" },
              help_text: { title: "帮助" },
            },
            disableDownload: true,
            // disableDelete: true,
            text__update_right: "设置",
          },
        },
        menu__error: {
          name: "错误预览",
          table: {
            path: "set_conf/errors/",
            columns: {
              code: { title: "标识码" },
              chinese: { title: "简体中文" },
              english: { title: "english" },
            },
            columnsDetailLine: {
              code: { title: "标识码" },
              chinese: { title: "简体中文" },
              english: { title: "english" },
            },
            columnsUpdate: {
              chinese: { title: "简体中文" },
              english: { title: "english" },
            },
            columnsCreate: {
              code: { title: "标识码" },
              chinese: { title: "简体中文" },
              english: { title: "english" },
            },
            disableDownload: true,
          },
        },
        menu__background: {
          name: "小程序背景图",
          table: {
            path: "wechat/share_image/",
            columns: {
              name: { title: "图片名称" },
              image: { title: "图片预览", type: "imgHead" },
            },
            columnsUpdate: {
              name: { title: "图片名称" },
              image: { title: "图片预览", type: "imgHead", ratio: 1 },
            },
            columnsCreate: {
              name: { title: "图片名称" },
              image: { title: "图片预览", type: "imgHead", ratio: 1 },
            },
            disableDownload: true,
          },
        },
      },
    },
    menu__pay: {
      name: "支付相关",
      sub: {
        menu__vip: {
          name: "VIP商品",
          table: {
            path: "pay/vip/",
            columns: {
              vip_code: {
                title: "vip类型",
                type: "map",
                map: {
                  1: "book talk",
                  2: "book talk--增加副卡槽位",
                },
              },
              price: {
                title: "vip价格",
                type: "render",
                render: (value) => {
                  if (!value) return value;
                  value = value / 100;
                  return value + "元";
                },
              },
              name: { title: "vip名称" },
            },
            columnsUpdate: {
              price: { title: "vip价格" },
              name: { title: "vip名称" },
            },
            disableDownload: true,
            disableDelete: true,
            text__update_right: "设置",
          },
        },
        menu__psy_list: {
          name: "支付状态",
          table: {
            path: "pay/pay_list/",
            columns: {
              nickName: { title: "微信昵称" },
              phone: { title: "手机号" },
              vip_code: {
                title: "vip类型",
                type: "map",
                map: {
                  1: "book talk",
                },
              },
              price: {
                title: "vip价格",
                type: "render",
                render: (value) => {
                  if (!value) return value;
                  value = value / 100;
                  return value + "元";
                },
              },
              name: { title: "vip名称" },
              total: {
                title: "支付金额",
                type: "render",
                render: (value) => {
                  if (!value) return value;
                  value = value / 100;
                  return value + "元";
                },
              },
              out_trade_no: { title: "订单号" },
              state: {
                title: "处理状态",
                type: "map",
                map: {
                  1: "未处理",
                  2: "已处理",
                },
              },
              description: { title: "描述" },
            },
            columnsDetailLine: {
              vip_code: {
                title: "vip类型",
                type: "map",
                map: {
                  1: "book talk",
                },
              },
              price: { title: "vip价格" },
              name: { title: "vip名称" },
              total: {
                title: "支付金额",
                type: "render",
                render: (value) => {
                  if (!value) return value;
                  value = value / 100;
                  return value + "元";
                },
              },
              out_trade_no: { title: "订单号" },
              state: {
                title: "处理状态",
                type: "map",
                map: {
                  1: "未处理",
                  2: "已处理",
                },
              },
              attach: { title: "标记" },
              description: { title: "描述" },
            },
            disableDownload: true,
            disableDelete: true,
          },
        },
      },
    },
    menu__works: {
      name: "读书管理",
      sub: {
        menu__channel: {
          name: "选择频道",
          table: {
            path: "api_v1/channel/",
            columns: {
              id: { title: "数据库ID号码" },
              channel_name: { title: "频道名称" },
              index: { title: "排序索引" },
              is_show: {
                title: "是否显示",
                type: "bool",
                textBool: ["显示", "不显示"],
              },
              private: {
                title: "功能频道",
                type: "bool",
                textBool: ["功能频道(有权限可看)", "公开频道(所有vip可看)"],
              },
            },
            columnsUpdate: {
              channel_name: { title: "频道名称" },
              index: { title: "排序索引" },
              is_show: { title: "是否显示", type: "bool" },
              private: {
                title: "功能频道",
                type: "singleSmallChoice",
                choices: [
                  { value: "false", help: "公开频道(所有vip都能看)" },
                  { value: "true", help: "功能频道(有权限的人才能看)" },
                ],
              },
            },
            columnsCreate: {
              channel_name: { title: "频道名称" },
              index: { title: "排序索引" },
              is_show: { title: "是否显示", type: "bool" },
              private: {
                title: "功能频道",
                type: "singleSmallChoice",
                choices: [
                  { value: "true", help: "功能频道(有权限的人才能看)" },
                  { value: "false", help: "公开频道(所有vip都能看)" },
                ],
              },
            },
            disableDownload: true,
            disableCopy: true,
            disableCreate: true,
            text__create_button: "新建频道",
            sub__book: {
              columnTitle: "读书管理",
              path: "api_back/book/",
              columns: {
                works: { title: "名称" },
                overview: { title: "概述" },
                // page: {title: '页数',},
                card: { title: "打卡次" },
                // score_card: {title: '打卡积分',},
                is_public: {
                  title: "是否公开",
                  type: "bool",
                  textBool: ["已上架", "未上架"],
                },
              },
              columnsDetailLine: {
                works: { title: "书本名称" },
                channel_obj_read: { title: "所属频道" },
                time_start: { title: "开始时间", type: "utcTime" },
                time_end: { title: "结束时间", type: "utcTime" },
                overview: { title: "概述" },
                // page: {title: '总页数',},
                card: { title: "要求打卡次数" },
                // score_card: {title: '每次打卡积分',},
                is_public: {
                  title: "是否上架",
                  type: "bool",
                  textBool: ["已上架", "未上架"],
                },
              },
              columnsUpdate: {
                works: { title: "书本名称" },
                time_start: { title: "开始时间", type: "time" },
                time_end: { title: "结束时间", type: "time" },
                // page: {title: '总页数',},
                card: { title: "要求打卡次数" },
                // score_card: {title: '每次打卡积分',},
                overview: { title: "概述" },
                is_public: { title: "是否上架", type: "bool" },
                channel_obj: {
                  title: "切换频道",
                  type: "singSearchChoice",
                  path: "api_back/choices_channel/",
                },
              },
              columnsCreate: {
                works: { title: "书本名称" },
                time_start: { title: "开始时间", type: "time" },
                time_end: { title: "结束时间", type: "time" },
                // page: {title: '总页数',},
                card: { title: "要求打卡次数" },
                // score_card: {title: '每次打卡积分',},
                overview: { title: "概述" },
                is_public: { title: "是否上架", type: "bool" },
              },
              disableDownload: true,
              disableCopy: true,
              disableCreate: true,
              text__create_button: "新建读书",
              sub__files: {
                columnTitle: "附件",
                path: "api_v1/files/",
                columns: {
                  file_type: {
                    title: "附件类型",
                    type: "map",
                    map: {
                      1: "封面",
                      3: "图片内容",
                    },
                  },
                  file_obj: {
                    title: "附件链接",
                    funType: (param) => {
                      let file_type = param.record.file_type;
                      let type = "";
                      if (file_type === 1 || file_type === 3) type = "imgHead";
                      return type;
                    },
                  },
                },
                columnsUpdate: {
                  file_type: {
                    title: "附件类型",
                    type: "singleSmallChoice",
                    choices: [
                      { value: 1, help: "封面" },
                      { value: 3, help: "图片内容" },
                    ],
                  },
                  file_obj: { title: "上传附件", type: "file" },
                },
                columnsCreate: {
                  file_type: {
                    title: "附件类型",
                    type: "singleSmallChoice",
                    choices: [
                      { value: 1, help: "封面" },
                      { value: 3, help: "图片内容" },
                    ],
                  },
                  file_obj: { title: "上传附件", type: "file" },
                },
                disableDownload: true,
                disableCopy: true,
                disableCreate: true,
                text__create_button: "新建附件",
              },
              sub__live: {
                columnTitle: "直播",
                path: "api_back/live/",
                columns: {
                  time_live: { title: "直播时间", type: "utcTime" },
                  theme: { title: "主题" },
                  platform_obj_read: { title: "直播平台" },
                  host_obj_read: { title: "主播" },
                },
                columnsUpdate: {
                  time_live: { title: "直播时间", type: "time" },
                  theme: { title: "主题" },
                  platform_obj: {
                    title: "直播平台",
                    type: "singSearchChoice",
                    path: "api_back/choices_platform/",
                  },
                  host_obj: {
                    title: "主播",
                    type: "singSearchChoice",
                    path: "api_back/choices_host/",
                  },
                },
                columnsCreate: {
                  time_live: { title: "直播时间", type: "time" },
                  theme: { title: "主题" },
                  platform_obj: {
                    title: "直播平台",
                    type: "singSearchChoice",
                    path: "api_back/choices_platform/",
                  },
                  host_obj: {
                    title: "主播",
                    type: "singSearchChoice",
                    path: "api_back/choices_host/",
                  },
                },
                disableDownload: true,
                // disableCopy: true,
                disableCreate: true,
                text__create_button: "新建直播",
                sub__videotape: {
                  columnTitle: "直播回放",
                  path: "api_back/videotape/",
                  columns: {
                    tape_name: { title: "录播名称" },
                    index: { title: "排序索引" },
                    // "link": {title: '链接', type: 'link',},
                    file_obj_read: { title: "链接", type: "file" },
                  },
                  columnsDetailLine: {
                    tape_name: { title: "录播名称" },
                    index: { title: "排序索引" },
                    // "link": {title: '链接', type: 'link',},
                    file_obj_read: { title: "链接", type: "file" },
                  },
                  columnsUpdate: {
                    tape_name: { title: "录播名称" },
                    index: { title: "排序索引" },
                    // "link": {title: '链接',},
                    file_obj: { title: "视频文件", type: "file" },
                  },
                  columnsCreate: {
                    tape_name: { title: "录播名称" },
                    index: { title: "排序索引" },
                    // "link": {title: '链接',},
                    file_obj: { title: "视频文件", type: "file" },
                  },
                  disableDownload: true,
                  disableCreate: true,
                  text__create_button: "增加记录",
                },
              },
              sub__message: {
                columnTitle: "评论",
                path: "api_back/message/",
                columns: {
                  created_at: { title: "创建时间", type: "utcTime" },
                  message: { title: "评论内容" },
                  about_obj_read: { title: "关联评论" },
                  creator_read: { title: "创建人" },
                },
                columnsUpdate: {
                  message: { title: "内容" },
                  about_obj: {
                    title: "关联评论",
                    type: "singSearchChoice",
                    path: "api_back/choices_message/",
                  },
                  creator: {
                    title: "创建人",
                    type: "singSearchChoice",
                    path: "api_back/choices_wechat/",
                  },
                },
                columnsCreate: {
                  message: { title: "内容" },
                  about_obj: {
                    title: "关联评论",
                    type: "singSearchChoice",
                    path: "api_back/choices_message/",
                  },
                  creator: {
                    title: "创建人",
                    type: "singSearchChoice",
                    path: "api_back/choices_wechat/",
                  },
                },
                disableDownload: true,
                disableCopy: true,
                disableCreate: true,
                text__create_button: "新建评论",
              },
              sub__host: {
                columnTitle: "特邀主播",
                path: "api_back/sub_host/",
                columns: {
                  nickName: { title: "微信昵称" },
                  avatarUrl: { title: "微信头像", type: "imgHead" },
                },
                columnsCreate: {
                  host_obj: {
                    title: "选择主播",
                    type: "singSearchChoice",
                    path: "api_back/choices_host/",
                  },
                },
                disableDownload: true,
                disableCopy: true,
                disableCreate: true,
                text__create_button: "添加主播",
                text__delete: "移除",
              },
              sub__idea: {
                columnTitle: "笔记",
                path: "api_back/idea/",
                columns: {
                  created_at: { title: "创建时间", type: "utcTime" },
                  idea_type: {
                    title: "内容类型",
                    type: "map",
                    map: {
                      1: "日记",
                      2: "语音",
                      3: "视频",
                    },
                  },
                  file_obj_read: { title: "文件", type: "file" },
                  content: { title: "文字" },
                  creator: { title: "作者" },
                  is_public: {
                    title: "是否公开",
                    type: "bool",
                    textBool: ["公开", "未公开"],
                  },
                },
                columnsUpdate: {
                  idea_type: {
                    title: "内容类型",
                    type: "singleSmallChoice",
                    choices: [
                      { value: 1, help: "日记" },
                      { value: 2, help: "语音" },
                      { value: 3, help: "视频" },
                    ],
                  },
                  file_obj: { title: "文件描述", type: "file" },
                  content: { title: "文字描述", type: "text" },
                  is_public: { title: "是否公开", type: "bool" },
                },
                columnsCreate: {
                  idea_type: {
                    title: "内容类型",
                    type: "singleSmallChoice",
                    choices: [
                      { value: 1, help: "日记" },
                      { value: 2, help: "语音" },
                      { value: 3, help: "视频" },
                    ],
                  },
                  file_obj: { title: "文件描述", type: "file" },
                  content: { title: "文字描述", type: "text" },
                  is_public: { title: "是否公开", type: "bool" },
                },
                disableDownload: true,
                disableCopy: true,
                disableCreate: true,
                text__create_button: "添加想法",
              },
              sub__point: {
                columnTitle: "打卡点",
                path: "api_back/point/",
                columns: {
                  id: { title: "数据库ID号码" },
                  name: { title: "打卡点名称" },
                  index: {
                    title: "第几次打卡",
                    type: "render",
                    render: (value) => `第${value}次`,
                  },
                  page: {
                    title: "打卡点百分比",
                    type: "render",
                    render: (value) => `${value}%`,
                  },
                  count: {
                    title: "已打卡人数",
                    type: "render",
                    render: (value) => `${value}人`,
                  },
                  // "pass_line": {
                  //     title: '及格线', type: 'render', render: value => {
                  //         if (!value) return '满分';
                  //         return `${value}分`;
                  //     },
                  // },
                },
                columnsUpdate: {
                  name: { title: "打卡点名称" },
                  index: { title: "第几次打卡" },
                  page: { title: "打卡点百分比", help: "请输入一个整数" },
                  // "pass_line": {title: '及格线', help_text: '及格线是多少分',},
                },
                columnsCreate: {
                  name: { title: "打卡点名称" },
                  index: { title: "第几次打卡" },
                  page: { title: "打卡点百分比", help: "请输入一个整数" },
                  // "pass_line": {title: '及格线', help_text: '及格线是多少分',},
                },
                disableDownload: true,
                disableCopy: true,
                text__create_button: "新建打卡点",
                sub__point_detail: {
                  columnTitle: "打卡人员",
                  path: "api_back/point_detail/",
                  columns: {
                    avatarUrl: { title: "微信头像", type: "imgHead" },
                    nickName: { title: "微信昵称" },
                    created_at: { title: "打卡时间", type: "utcTime" },
                    state: {
                      title: "是否打卡成功",
                      type: "bool",
                      textBool: ["打卡成功", "打卡失败"],
                    },
                    score: { title: "打卡得分" },
                  },
                  disableDownload: true,
                },
                sub__sub_que: {
                  columnTitle: "题目",
                  path: "api_back/sub_que/",
                  columns: {
                    id: { title: "数据库ID号码" },
                    content: { title: "题目" },
                    count_answer: { title: "选项数量" },
                    score: {
                      title: "本题分值",
                      render: (value) => `${value}分`,
                    },
                  },
                  columnsCreate: {
                    content: { title: "题目" },
                    count_answer: { title: "选项数量" },
                    score: { title: "本题分值" },
                  },
                  columnsUpdate: {
                    content: { title: "题目" },
                    count_answer: { title: "选项数量" },
                    score: { title: "本题分值" },
                  },
                  disableDownload: true,
                  text__create_button: "新建问题",
                  sub__sub_ans: {
                    columnTitle: "选项",
                    path: "api_back/sub_ans/",
                    columns: {
                      id: { title: "数据库ID号码" },
                      index: { title: "序号" },
                      content: { title: "描述" },
                      is_ok: {
                        title: "是否正确答案",
                        type: "bool",
                        textBool: ["正确", "错误"],
                      },
                    },
                    columnsUpdate: {
                      content: { title: "描述" },
                      is_ok: { title: "是否正确答案", type: "bool" },
                    },
                    disableDownload: true,
                  },
                },
                sub__idea: {
                  columnTitle: "笔记",
                  path: "api_back/idea_book/",
                  columns: {
                    created_at: { title: "创建时间", type: "utcTime" },
                    idea_type: {
                      title: "内容类型",
                      type: "map",
                      map: {
                        1: "日记",
                        2: "语音",
                        3: "视频",
                      },
                    },
                    file_obj_read: { title: "文件", type: "file" },
                    content: { title: "文字" },
                    creator: { title: "作者" },
                    is_public: {
                      title: "是否公开",
                      type: "bool",
                      textBool: ["公开", "未公开"],
                    },
                  },
                  columnsUpdate: {
                    idea_type: {
                      title: "内容类型",
                      type: "singleSmallChoice",
                      choices: [
                        { value: 1, help: "日记" },
                        { value: 2, help: "语音" },
                        { value: 3, help: "视频" },
                      ],
                    },
                    file_obj: { title: "文件描述", type: "file" },
                    content: { title: "文字描述", type: "text" },
                    is_public: { title: "是否公开", type: "bool" },
                  },
                  columnsCreate: {
                    idea_type: {
                      title: "内容类型",
                      type: "singleSmallChoice",
                      choices: [
                        { value: 1, help: "日记" },
                        { value: 2, help: "语音" },
                        { value: 3, help: "视频" },
                      ],
                    },
                    file_obj: { title: "文件描述", type: "file" },
                    content: { title: "文字描述", type: "text" },
                    is_public: { title: "是否公开", type: "bool" },
                  },
                  disableDownload: true,
                  disableCopy: true,
                  disableCreate: true,
                  text__create_button: "添加想法",
                },
              },
            },
            sub__article: {
              columnTitle: "文章管理",
              path: "api_back/article/",
              columns: {
                works: { title: "文章名称" },
                channel_obj_read: { title: "所属频道" },
                is_public: {
                  title: "是否公开",
                  type: "bool",
                  textBool: ["已上架", "未上架"],
                },
              },
              columnsUpdate: {
                works: { title: "文章名称" },
                is_public: { title: "是否上架", type: "bool" },
              },
              columnsCreate: {
                works: { title: "文章名称" },
                is_public: { title: "是否上架", type: "bool" },
              },
              disableDownload: true,
              disableCopy: true,
              disableCreate: true,
              text__create_button: "新建文章",
              sub__host: {
                columnTitle: "特邀主播",
                path: "api_back/sub_host/",
                columns: {
                  nickName: { title: "微信昵称" },
                  avatarUrl: { title: "微信头像", type: "imgHead" },
                },
                columnsCreate: {
                  host_obj: {
                    title: "选择主播",
                    type: "singSearchChoice",
                    path: "api_back/choices_host/",
                  },
                },
                disableDownload: true,
                disableCopy: true,
                disableCreate: true,
                text__create_button: "添加主播",
                text__delete: "移除",
              },
              sub__files: {
                columnTitle: "附件管理",
                path: "api_v1/files/",
                columns: {
                  file_type: {
                    title: "附件类型",
                    type: "map",
                    map: {
                      1: "图片",
                    },
                  },
                  file_obj: {
                    title: "附件链接",
                    funType: (param) => {
                      let file_type = param.record.file_type;
                      let type = "";
                      if (file_type === 1) type = "imgHead";
                      return type;
                    },
                  },
                },
                columnsUpdate: {
                  file_type: {
                    title: "附件类型",
                    type: "singleSmallChoice",
                    choices: [{ value: 1, help: "封面" }],
                  },
                  file_obj: { title: "上传附件", type: "file" },
                },
                columnsCreate: {
                  file_type: {
                    title: "附件类型",
                    type: "singleSmallChoice",
                    choices: [{ value: 1, help: "封面" }],
                  },
                  file_obj: { title: "上传附件", type: "file" },
                },
                disableDownload: true,
                disableCopy: true,
                disableCreate: true,
                text__create_button: "新建附件",
              },
              sub__message: {
                columnTitle: "评论管理",
                path: "api_back/message/",
                columns: {
                  created_at: { title: "创建时间", type: "utcTime" },
                  message: { title: "评论内容" },
                  about_obj_read: { title: "关联评论" },
                },
                columnsUpdate: {
                  message: { title: "内容" },
                  about_obj: {
                    title: "关联评论",
                    type: "singSearchChoice",
                    path: "api_back/choices_message/",
                  },
                  creator: {
                    title: "创建人",
                    type: "singSearchChoice",
                    path: "api_back/choices_wechat/",
                  },
                },
                columnsCreate: {
                  message: { title: "内容" },
                  about_obj: {
                    title: "关联评论",
                    type: "singSearchChoice",
                    path: "api_back/choices_message/",
                  },
                  creator: {
                    title: "创建人",
                    type: "singSearchChoice",
                    path: "api_back/choices_wechat/",
                  },
                },
                disableDownload: true,
                // disableCopy: true,
                disableCreate: true,
                text__create_button: "新建评论",
              },
              sub__idea: {
                columnTitle: "笔记",
                path: "api_back/idea_article/",
                columns: {
                  created_at: { title: "创建时间", type: "utcTime" },
                  idea_type: {
                    title: "内容类型",
                    type: "map",
                    map: {
                      1: "日记",
                      2: "语音",
                      3: "视频",
                    },
                  },
                  file_obj_read: { title: "文件", type: "file" },
                  content: { title: "文字" },
                  creator: { title: "作者" },
                  is_public: {
                    title: "是否公开",
                    type: "bool",
                    textBool: ["公开", "未公开"],
                  },
                },
                columnsUpdate: {
                  idea_type: {
                    title: "内容类型",
                    type: "singleSmallChoice",
                    choices: [
                      { value: 1, help: "日记" },
                      { value: 2, help: "语音" },
                      { value: 3, help: "视频" },
                    ],
                  },
                  file_obj: { title: "文件描述", type: "file" },
                  content: { title: "文字描述", type: "text" },
                  is_public: { title: "是否公开", type: "bool" },
                },
                columnsCreate: {
                  idea_type: {
                    title: "内容类型",
                    type: "singleSmallChoice",
                    choices: [
                      { value: 1, help: "日记" },
                      { value: 2, help: "语音" },
                      { value: 3, help: "视频" },
                    ],
                  },
                  file_obj: { title: "文件描述", type: "file" },
                  content: { title: "文字描述", type: "text" },
                  is_public: { title: "是否公开", type: "bool" },
                },
                disableDownload: true,
                disableCopy: true,
                disableCreate: true,
                text__create_button: "添加想法",
              },
            },
            sub__channel_wechat: {
              columnTitle: "访问权限",
              path: "api_v1/sub_channel_wechat/",
              columns: {
                nickName: { title: "微信昵称" },
                avatarUrl: { title: "头像", type: "imgHead" },
                remark: { title: "微信备注" },
              },
              columnsCreate: {
                wechat_obj: {
                  title: "选择人员",
                  type: "singSearchChoice",
                  path: "api_v1/choices_channel_wechat/",
                },
              },
              disableDownload: true,
              disableCopy: true,
              disableCreate: true,
              text__delete: "解除权限",
              text__create_button: "新建权限",
            },
          },
        },
        menu__platform: {
          name: "直播平台",
          table: {
            path: "api_back/platform/",
            columns: {
              platform_name: { title: "平台名称" },
            },
            columnsUpdate: {
              platform_name: { title: "平台名称" },
            },
            columnsCreate: {
              platform_name: { title: "平台名称" },
            },
            disableDownload: true,
            disableCopy: true,
            disableCreate: true,
            text__create_button: "新建平台",
          },
        },
      },
    },
    menu__news: {
      name: "通知管理",
      table: {
        path: "api_back/news/",
        columns: {
          created_at: { title: "创建时间", type: "utcTime" },
          updated_at: { title: "修改时间", type: "utcTime" },
          content: { title: "文字内容", type: "text" },
          is_show: {
            title: "是否在微信展示",
            type: "bool",
            textBool: ["展示", "不展示"],
          },
          index: { title: "排序索引" },
          cover: { title: "封面", type: "imgPath" },
        },
        columnsUpdate: {
          index: { title: "排序索引" },
          is_show: { title: "是否在微信展示", type: "bool" },
          content: { title: "文字内容", type: "text" },
        },
        columnsCreate: {
          index: { title: "排序索引" },
          is_show: { title: "是否在微信展示", type: "bool" },
          content: { title: "文字内容", type: "text" },
        },
        disableDownload: true,
        disableCopy: true,
        disableCreate: true,
        text__create_button: "新建通知",
        sub__news_file: {
          columnTitle: "附件",
          path: "api_back/news_file/",
          columns: {
            file_obj: { title: "图片链接", type: "imgPath" },
            file_type: {
              title: "图片类型",
              type: "map",
              map: {
                1: "封面",
                2: "内容",
              },
            },
          },
          columnsCreate: {
            file_obj: { title: "图片", type: "imgPath" },
            file_type: {
              title: "图片类型",
              type: "singleSmallChoice",
              choices: [
                { value: 1, help: "封面" },
                { value: 2, help: "内容" },
              ],
            },
          },
          disableDownload: true,
          disableCopy: true,
          disableCreate: true,
          text__create_button: "新建附件",
        },
      },
    },
    menu__static_file: {
      name: "静态文件",
      table: {
        path: "api_back/static_file/",
        columns: {
          path: { title: "路径" },
          look: { title: "预览", type: "file" },
          message: { title: "备注" },
        },
        columnsUpdate: {
          file_obj: { title: "上传文件", type: "file" },
          message: { title: "备注" },
        },
        columnsCreate: {
          file_obj: { title: "上传文件", type: "file" },
          message: { title: "备注" },
        },
        disableDownload: true,
        disableCopy: true,
        disableCreate: true,
        text__create_button: "上传静态文件",
      },
    },
  },
  ceo: {
    webTitle: "spike20系统",
    systemTitle: "CEO数据控制台",
    footer: Spike20Footer,
    menu__account: {
      name: "帐号",
      sub: {
        menu__base_account: {
          name: "帐号一览",
          table: {
            path: "programmer/user/",
            columns: {
              name: { title: "名字" },
              username: { title: "系统帐号" },
              role: { title: "角色信息" },
              cover: { title: "头像", type: "imgHead" },
            },
            columnsUpdate: {
              name: { title: "名字" },
              username: { title: "系统帐号" },
              password: { title: "密码" },
              cover: { title: "头像", type: "imgHead" },
            },
            text__update_title: "修改这个系统账号",
            disableDownload: true,
          },
        },
        menu__roles: {
          name: "角色一栏",
          table: {
            path: "programmer/role/",
            columns: {
              role_name: { title: "角色名" },
              code: { title: "系统识别名" },
            },
            disableDownload: true,
            disableDelete: true,
          },
        },
        menu__wechat_account: {
          name: "微信帐号",
          table: {
            path: "api_v1/wechat/",
            columns: {
              avatarUrl: { title: "头像", type: "imgPath" },
              created_at: { title: "创建于", type: "chinaTime" },
              phone: { title: "手机号" },
              remark: { title: "备注" },
              nickName: { title: "昵称" },
            },
            sub__sub_content: {
              columnTitle: "ta推荐了谁",
              path: "api_v1/sub_wechat/",
              columns: {
                avatarUrl: { title: "头像", type: "imgPath" },
                created_at: { title: "创建于", type: "chinaTime" },
                phone: { title: "手机号" },
                remark: { title: "备注" },
                nickName: { title: "昵称" },
              },
              disableDownload: true,
              disableDelete: true,
            },
            sub__sub_vip: {
              columnTitle: "ta的会员",
              path: "programmer/sub_vip/",
              columns: {
                valid_to: { title: "有效期至", type: "utcTime" },
                vip_code: {
                  title: "会员类型",
                  type: "map",
                  map: {
                    1: "book talk",
                  },
                },
                name: { title: "会员名称" },
              },
              columnsCreate: {
                valid_to: { title: "有效期至", type: "time" },
                vip_obj: {
                  title: "会员类型",
                  type: "singSearchChoice",
                  path: "programmer/choices_vip/",
                },
              },
              disableDownload: true,
              disableCopy: true,
              disableCreate: true,

              text__create_button: "添加会员",
            },
            disableDelete: true,
          },
        },
      },
    },
    menu__conf: {
      name: "系统设置",
      sub: {
        menu__conf: {
          name: "系统设置",
          table: {
            path: "set_conf/set_conf/",
            columns: {
              name: { title: "配置项" },
              value: {
                title: "值",
                type: "render",
                render: (value, param) => {
                  let record = param.record;
                  let key = record.key;
                  if (key === "language") {
                    if (value === "1") return "简体中文";
                    if (value === "2") return "english";
                  }
                  return value;
                },
              },
              help_text: { title: "帮助" },
            },
            columnsDetailLine: {
              name: { title: "配置项" },
              value: { title: "值" },
              help_text: { title: "帮助" },
            },
            columnsUpdate: {
              value: { title: "值" },
            },
            disableDownload: true,
            disableDelete: true,
            disableCreate: true,
            text__update_right: "设置",
          },
        },
        menu__error: {
          name: "错误预览",
          table: {
            path: "set_conf/errors/",
            columns: {
              // code: {title: '标识码',},
              chinese: { title: "简体中文" },
              english: { title: "english" },
            },
            columnsDetailLine: {
              code: { title: "标识码" },
              chinese: { title: "简体中文" },
              english: { title: "english" },
            },
            columnsUpdate: {
              chinese: { title: "简体中文" },
              english: { title: "english" },
            },
            disableDownload: true,
            disableDelete: true,
          },
        },
        menu__background: {
          name: "小程序背景图",
          table: {
            path: "wechat/share_image/",
            columns: {
              name: { title: "图片名称" },
              image: { title: "图片预览", type: "imgHead" },
            },
            columnsUpdate: {
              name: { title: "图片名称" },
              image: { title: "图片预览", type: "imgHead", ratio: 1 },
            },
            columnsCreate: {
              name: { title: "图片名称" },
              image: { title: "图片预览", type: "imgHead", ratio: 1 },
            },
            disableDownload: true,
          },
        },
      },
    },
    menu__pay: {
      name: "支付相关",
      sub: {
        menu__vip: {
          name: "VIP商品",
          table: {
            path: "pay/vip/",
            columns: {
              vip_code: {
                title: "vip类型",
                type: "map",
                map: {
                  1: "book talk",
                },
              },
              price: {
                title: "vip价格",
                type: "render",
                render: (value) => {
                  if (!value) return value;
                  value = value / 100;
                  return value + "元";
                },
              },
              name: { title: "vip名称" },
            },
            columnsUpdate: {
              price: { title: "vip价格" },
              name: { title: "vip名称" },
            },
            disableDownload: true,
            disableDelete: true,
            text__update_right: "设置",
          },
        },
        menu__psy_list: {
          name: "支付状态",
          table: {
            path: "pay/pay_list/",
            columns: {
              vip_code: {
                title: "vip类型",
                type: "map",
                map: {
                  1: "book talk",
                },
              },
              price: {
                title: "vip价格",
                type: "render",
                render: (value) => {
                  if (!value) return value;
                  value = value / 100;
                  return value + "元";
                },
              },
              name: { title: "vip名称" },
              total: { title: "支付金额" },
              out_trade_no: { title: "订单号" },
              state: {
                title: "处理状态",
                type: "map",
                map: {
                  1: "未处理",
                  2: "已处理",
                },
              },
              description: { title: "描述" },
            },
            columnsDetailLine: {
              vip_code: {
                title: "vip类型",
                type: "map",
                map: {
                  1: "book talk",
                },
              },
              price: { title: "vip价格" },
              name: { title: "vip名称" },
              total: { title: "支付金额" },
              out_trade_no: { title: "订单号" },
              state: {
                title: "处理状态",
                type: "map",
                map: {
                  1: "未处理",
                  2: "已处理",
                },
              },
              attach: { title: "标记" },
              description: { title: "描述" },
            },
            disableDownload: true,
            disableDelete: true,
          },
        },
      },
    },
    menu__channel: {
      name: "读书频道",
      table: {
        path: "api_v1/channel/",
        columns: {
          channel_name: { title: "频道名称" },
          index: { title: "排序索引" },
        },
        columnsUpdate: {
          channel_name: { title: "频道名称" },
          index: { title: "排序索引" },
        },
        columnsCreate: {
          channel_name: { title: "频道名称" },
          index: { title: "排序索引" },
        },
        disableDownload: true,
        disableDelete: true,
        disableCopy: true,
        disableCreate: true,
        text__create_button: "新建频道",
      },
    },
  },
  selectedMenu: {},
  fieldMapping: fieldMapping,
  fieldDetailMapping: fieldDetailMapping,
};
export { setting, Text };
