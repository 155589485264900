import React from "react";
import {Cascader} from 'antd';
import {InputFrameworkField} from "../InputFramework/InputFramework";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './single-deep-choices.css';


//单选类型的字段(简短的)
class FieldSingleDeepChoices extends React.Component {
    constructor(props) {
        super(props);
        this.getContent = this.getContent.bind(this);
    }

    getPartPath() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');

        // 计算正确的updatePart空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let nowPagePath;
        if (action === 'update') {
            if (spacePrivatePath) nowPagePath = spacePrivatePath.concat(['updatePart', 'nowPage',],);
            let nowPage = tool.getStateSpace(nowPagePath);
            if (!nowPage) nowPage = 1;
            let updatePartSpaceKey = `columnsUpdate_${nowPage}`;
            let columnsUpdatePartPath;
            columnsUpdatePartPath = spacePublicPath.concat([updatePartSpaceKey]);
            return columnsUpdatePartPath;
        } else {
            if (spacePrivatePath) nowPagePath = spacePrivatePath.concat(['createPart', 'nowPage',],);
            let nowPage = tool.getStateSpace(nowPagePath);
            if (!nowPage) nowPage = 1;
            let createPartSpaceKey = `columnsCreate_${nowPage}`;
            let columnsCreatePartPath;
            columnsCreatePartPath = spacePublicPath.concat([createPartSpaceKey]);
            return columnsCreatePartPath;
        }

    }

    updateChoices() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);

        // 兼容获取columnsCreate/columnsUpdate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) {
            let columnsUpdatePartPath = this.getPartPath();
            columnsUpdate = tool.getStateSpace(columnsUpdatePartPath);

            let columnsCreatePartPath = this.getPartPath();
            columnsCreate = tool.getStateSpace(columnsCreatePartPath);
        }

        let fatherRecord = tool.getAttribute(paramModal, 'fatherRecord');
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnUpdatePath = columnsUpdatePath.concat([columnKey]);
        // let columnCreatePath = columnsCreatePath.concat([columnKey]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);


        let columnsUpdatePartPath = this.getPartPath();
        let columnsCreatePartPath = this.getPartPath();
        // console.log('columnsUpdatePartPath',columnsUpdatePartPath)
        if (action === 'update' && columnInfo) {
            let path = tool.getAttribute(columnUpdate, 'path');
            if (!path) return null;
            path = path[0];

            let url = tool.get_url(path, false, {
                // father_id: fatherRecord.id,
                father_id: tool.getAttribute(fatherRecord, 'id'),
                page_size: 10000,
            });

            let requestParam = {
                url: url,
                success: data => {
                    // let columnsUpdatePart = tool.getStateSpace(columnsUpdatePartPath);
                    // console.log('columnsUpdatePart',columnsUpdatePart)
                    // 加工成框架要求的格式
                    let choices = [];
                    for (let i = 0; i < data.results.length; i++) {
                        let line = data.results[i];
                        let help = line.help;
                        let value = line.value;

                        choices.push({
                            value: value,
                            label: help,
                            isLeaf: false,
                        });
                    }

                    // 为字段添加choices
                    columnUpdate['choices'] = choices;
                    let columnUpdatePath = columnsUpdatePartPath.concat([columnKey]);

                    tool.setStateSpace(columnUpdatePath, columnUpdate);
                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);
        }
        if (action === 'update' && !columnInfo) {
            let path = tool.getAttribute(columnCreate, 'path');
            if (!path) return null;
            path = path[0];

            let url = tool.get_url(path, false, {
                // father_id: fatherRecord.id,
                father_id: tool.getAttribute(fatherRecord, 'id'),
                page_size: 10000,
            });

            let requestParam = {
                url: url,
                success: data => {
                    columnUpdate = Object.assign({}, columnUpdate);
                    // 加工成框架要求的格式
                    let choices = [];
                    for (let i = 0; i < data.results.length; i++) {
                        let line = data.results[i];
                        let help = line.help;
                        let value = line.value;

                        choices.push({
                            value: value,
                            label: help,
                            isLeaf: false,
                        });
                    }

                    columnUpdate['choices'] = choices;
                    tool.setStateSpace(columnUpdatePath, columnUpdate);
                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);
        }
        if (action === 'create' || action === 'copy') {

            let path = tool.getAttribute(columnCreate, 'path');
            if (!path) return null;
            path = path[0];

            let url = tool.get_url(path, false, {
                // father_id: fatherRecord.id,
                father_id: tool.getAttribute(fatherRecord, 'id'),
                page_size: 10000,
            });

            let requestParam = {
                url: url,
                success: data => {
                    columnCreate = Object.assign({}, columnCreate);
                    // 加工成框架要求的格式
                    let choices = [];
                    for (let i = 0; i < data.results.length; i++) {
                        let line = data.results[i];
                        let help = line.help;
                        let value = line.value;

                        choices.push({
                            value: value,
                            label: help,
                            isLeaf: false,
                        });
                    }

                    columnCreate['choices'] = choices;

                    let columnCreatePartPath = columnsCreatePartPath.concat([columnKey]);
                    tool.setStateSpace(columnCreatePartPath, columnCreate);
                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);
        }
    }

    componentDidMount() {
        this.updateChoices();

        //如果是拷贝模式,需要提前写入数据,而不是等到变化
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        if (action === 'copy') {
            let infoModalPath = ['infoModal'];
            let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
            let defaultValue = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);
            let dataKey = `create__x`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            data = Object.assign({}, data);
            data[columnKey] = defaultValue;
            tool.setStateSpace(dataPath, data);
        }
    }

    getContent() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);

        // 兼容获取columnCreate/columnUpdate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) {
            let columnsUpdatePartPath = this.getPartPath();
            columnsUpdate = tool.getStateSpace(columnsUpdatePartPath);

            let columnsCreatePartPath = this.getPartPath();
            columnsCreate = tool.getStateSpace(columnsCreatePartPath);
        }

        let record = tool.getAttribute(paramModal, 'record');
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        if (columnInfo && action === 'update') {
            let columnsUpdatePartPath = this.getPartPath();
            columnsUpdate = tool.getStateSpace(columnsUpdatePartPath);

            let columnsCreatePartPath = this.getPartPath();
            columnsCreate = tool.getStateSpace(columnsCreatePartPath);
        }
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);




        let choices = tool.getAttribute(columnCreate, 'choices');
        if (action === 'update') {
            choices = tool.getAttribute(columnUpdate, 'choices');
        }
        if (action === 'create') {
            choices = tool.getAttribute(columnCreate, 'choices');
        }
        if (!choices) choices = [];

        // 请求路径
        let path;
        if (action === 'update') {
            path = tool.getAttribute(columnUpdate, 'path');
        }
        if (action === 'create' || action === 'copy') {
            path = tool.getAttribute(columnCreate, 'path');
        }
        let path_origin = path;
        if (!path_origin) path_origin = [];


        let onChange = (value, selectedOptions) => {
            let count_path = path_origin.length;
            if (selectedOptions.length < count_path) return null;

            // 把值记录到私有域
            let target_value = selectedOptions[selectedOptions.length - 1].value;
            // if (target_value === '0') target_value = null;

            if (action === 'update') {
                let dataKey = `update__${record.id}`;
                let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                let data = tool.getStateSpace(dataPath);
                data = Object.assign({}, data);
                data[columnKey] = target_value;
                tool.setStateSpace(dataPath, data);
            }
            if (action === 'create' || action === 'copy') {
                // let setting=Object.assign({},tool.getStateSpace(['setting']));
                // tool.setStateSpace(['setting'],setting)
                let dataKey = `create__x`;
                let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                let data = tool.getStateSpace(dataPath);
                data = Object.assign({}, data);
                data[columnKey] = target_value;
                tool.setStateSpace(dataPath, data);
            }
        };
        let loadData = selectedOptions => {
            const targetOption = selectedOptions[selectedOptions.length - 1];
            targetOption.loading = true;

            // 请求数据
            let use_path = path[selectedOptions.length];

            let url = tool.get_url(use_path, false, {
                father_id: tool.getAttribute(targetOption, 'value'),
                page_size: 10000,
            });

            let requestParam = {
                url: url,
                success: data => {
                    // 计算是否需要枝叶
                    let count_path = path_origin.length;
                    let selectedOptionsCount = selectedOptions.length;
                    let isLeaf = false;
                    if ((selectedOptionsCount + 1) >= count_path) isLeaf = true;

                    targetOption.loading = false;
                    let results = data.results;
                    let children = [];
                    for (let i = 0; i < results.length; i++) {
                        let line = results[i];
                        let temp = {
                            label: line.help,
                            value: line.value,
                            isLeaf: isLeaf,
                        };
                        if (!isLeaf) temp['loading'] = false;
                        children.push(temp);

                        targetOption.children = children;
                    }
                    let setting = tool.getStateSpace(['setting']);
                    setting = Object.assign({}, setting);
                    tool.setStateSpace(['setting'], setting);
                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);
        };

        return <Cascader
            options={choices}
            loadData={loadData}
            onChange={onChange}
            placeholder="请选择"
            changeOnSelect
        />;

    }

    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);

        // 兼容获取columnCreate/columnUpdate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) columnCreate = columnInfo;
        if (columnInfo) columnUpdate = columnInfo;

        let title = tool.getAttribute(columnUpdate, 'title');
        let help = tool.getAttribute(columnUpdate, 'help');
        if (action === 'create' || action === 'copy') {
            title = tool.getAttribute(columnCreate, 'title');
            help = tool.getAttribute(columnCreate, 'help');
        }

        return <InputFrameworkField
            param={{
                getContent: this.getContent,
                title: title,
                help: help,
                action: action,
            }}

        />;
    }
}

function mapStateToSingle(state) {
    return {
        setting: state.setting,
    }
}

FieldSingleDeepChoices = connect(mapStateToSingle)(FieldSingleDeepChoices);

export {FieldSingleDeepChoices,};
