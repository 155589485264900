import React from "react";
import {Button} from 'antd';
import ArrowDownOutlined from "@ant-design/icons/lib/icons/ArrowDownOutlined";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './img-path.css';
import $ from "jquery";
import {Image, Tooltip} from "antd";


//图片路径类型的字段
class FieldImagePath extends React.Component {
    constructor(props) {
        super(props);
        this.getContent = this.getContent.bind(this);
    }

    getContent() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let record = tool.getAttribute(paramModal, 'record');
        //制作临时id
        let inputId = Math.ceil(Math.random() * 10).toString();


        return (
            <div className={'fields-img-path-button'}>
                <input
                    id={inputId}
                    className={'file-input'}
                    type={'file'}
                    onChange={event => {
                        let files = event.target.files;
                        let file = null;
                        if (files.length > 0) file = event.target.files[0];

                        if (action === 'update') {
                            let dataKey = `update__${record.id}`;
                            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                            let data = tool.getStateSpace(dataPath);
                            data = Object.assign({}, data);
                            data[columnKey] = file;
                            tool.setStateSpace(dataPath, data);
                        } else if (action === 'create' || action === 'copy') {
                            let dataKey = `create__x`;
                            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                            let data = tool.getStateSpace(dataPath);
                            data = Object.assign({}, data);
                            data[columnKey] = file;
                            // console.log('file3-------------',file)
                            tool.setStateSpace(dataPath, data);
                        }
                    }}
                    accept={"image/gif, image/jpeg,image/jpg,image/png,image/bmp,"}
                />
                <div className={'fields-img-path-button-one'}>
                    <Button
                        type={'primary'}
                        onClick={() => $(`#${inputId}`).click()}
                    >
                        点击换图
                    </Button>
                </div>

                <div className={'fields-img-path-button-one'}>
                    <Button
                        type={'primary'}
                        onClick={() => {
                            let dataKey = `create__x`;
                            if (action === 'update') dataKey = `update__${record.id}`;
                            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                            let data = tool.getStateSpace(dataPath);
                            data = Object.assign({}, data);
                            data[columnKey] = null;
                            tool.setStateSpace(dataPath, data);
                        }}
                        danger
                    >
                        置空图片
                    </Button>
                </div>
            </div>
        )
    }

    getOriginImgReact() {

        // 得到默认值
        let defaultValue = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);
        let src = tool.get_url(defaultValue);
        return (<div className={'fields-img-path-img-border'}>
            {defaultValue ? <Image
                src={src}
                className={'fields-img-path-img-border'}
            /> : null}
            {defaultValue ? null : '空的'}
        </div>);
    }

    getMiddle() {

        return (<div className={'fields-img-path-middle'}>
            <ArrowDownOutlined/>
        </div>);
    }

    getNowImgReact() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let record = tool.getAttribute(paramModal, 'record');
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);

        //得到正在储存的文件
        let originFile;
        if (action === 'create' || action === 'copy') {
            let dataKey = `create__x`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            if (!data) data = {};
            originFile = data[columnKey];
        } else if (action === 'update') {
            let dataKey = `update__${record.id}`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            if (!data) data = {};
            originFile = data[columnKey];
        }


        let fileInfo = '还没有选择图片';
        if (!originFile && originFile !== undefined) fileInfo = '置空';
        if (originFile) {
            //计算文件大小
            let back = 'B';
            let fileSize = originFile.size;
            if (fileSize > 1024) {
                fileSize = fileSize / 1024;
                back = 'Kb';
            }
            if (fileSize > 1024) {
                fileSize = fileSize / 1024;
                back = 'Mb';
            }
            if (fileSize > 1024) {
                fileSize = fileSize / 1024;
                back = 'Gb';
            }
            if (fileSize > 1024) {
                fileSize = fileSize / 1024;
                back = 'Pb';
            }

            fileSize = fileSize.toFixed(2);
            let sizeInfo = `${fileSize}${back}`;

            fileInfo = (<div>
                <div className={'file-info-line'}>
                    <div className={'file-info-left'}>图片名称:</div>
                    {originFile.name}
                </div>
                <div className={'file-info-line'}>
                    <div className={'file-info-left'}>图片大小:</div>
                    {sizeInfo}
                </div>
                <div className={'file-info-line'}>
                    <div className={'file-info-left'}>图片类型:</div>
                    {originFile.type}
                </div>
            </div>);
        }

        return (<div className={'fields-img-path-img-border'}>
            {fileInfo}
        </div>);
    }

    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);

        // 兼容获取columnCreate/columnUpdate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) columnCreate = columnInfo;
        if (columnInfo) columnUpdate = columnInfo;

        let title = tool.getAttribute(columnUpdate, 'title');
        let help = tool.getAttribute(columnUpdate, 'help');
        if (action === 'create' || action === 'copy') {
            title = tool.getAttribute(columnCreate, 'title');
            help = tool.getAttribute(columnCreate, 'help');
        }


        let originImgReact = this.getOriginImgReact();
        let middle = this.getMiddle();
        let nowImageReact = this.getNowImgReact();

        let content = this.getContent();


        return (<div>
            <Tooltip title={help}>
                <div style={{marginBottom: '5px',}}>
                    <div className={'title'}>
                        {title}
                    </div>
                </div>
            </Tooltip>
            {originImgReact}
            {middle}
            {nowImageReact}

            {content}
        </div>);
    }
}

function mapStateToUpdate(state) {
    return {
        setting: state.setting,
    }
}

FieldImagePath = connect(mapStateToUpdate)(FieldImagePath);

export {FieldImagePath,};
