import React from 'react';
import {connect} from 'react-redux';
import './checkout_page.css';
import {Button} from "antd";
import {tool} from "../../tools/tools";


//切换页面
class CheckoutPageUnConnected extends React.Component {
    onClick(key) {
        let nowPagePath = ['nowPage',];
        tool.setStateSpace(nowPagePath, key,);
    }

    render() {
        let pageMap = tool.getStateSpace(['setting', 'pageMap',], {},);

        let buttons = [];
        for (let key in pageMap) {
            let value = pageMap[key];
            let title = tool.getAttribute(value, 'title', '标题未配置',);
            let onTitle = tool.getAttribute(value, 'onTitle', false,);
            // let react = tool.getAttribute(value, 'react', null,);
            if (!onTitle) continue;

            buttons.push(<Button type={'link'} key={key} onClick={() => this.onClick(key)}>
                {title}
            </Button>);
        }

        return <div className={'checkout_page-button'}>{buttons}</div>
    }
}

function state_to_page(state) {

    return {};
}

function page_to_state(_) {
    return {};
}

let CheckoutPage = connect(state_to_page, page_to_state)(CheckoutPageUnConnected);

export {CheckoutPage}
