import React from 'react';
import {Select} from "antd";
import {connect} from 'react-redux';
import {tool} from "../../../tools/tools";

import './filters.css';

//下载组件
class FilterUnConnected extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.getFilters = this.getFilters.bind(this);
    }

    onChange(value) {
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        // let spacePrivate = tool.getStateSpace(spacePrivatePath);

        // 修改静态过滤器的值
        let filterValuePath = spacePrivatePath.concat(['filterValue']);
        tool.setStateSpace(filterValuePath, value);

        // 修改更新状态
        let stateTableOnloadPath = spacePrivatePath.concat(['stateTableOnload']);
        tool.setStateSpace(stateTableOnloadPath, 'filter');

        // 重新载入字段信息
    }

    getFilters() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);

        // 过滤器信息
        let filterSpace = tool.getAttribute(spacePublic, 'filters');
        if (!filterSpace) return null;

        // 过滤器选项信息
        let filterChoices = tool.getAttribute(filterSpace, 'choices');

        let Option = Select.Option;
        let filterChoiceKeys = Object.keys(filterChoices);
        let options = [];
        for (let filterChoiceKey of filterChoiceKeys) {
            let filterInfo = filterChoices[filterChoiceKey];
            let filterText = tool.getAttribute(filterInfo, 'text');
            if (!filterText) filterText = '未定义';
            // console.log('--------', filterInfo)
            options.push(<Option value={filterChoiceKey} key={filterText}>{filterText}</Option>);
        }

        // 得到当前的值
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }

        return (<div className={'filters'}>
            <Select
                value={filterValue}
                onChange={value => this.onChange(value)}
                className={'filters_choices'}
            >
                {options}
            </Select>
        </div>);
    }

    render() {
        return this.getFilters();
    }
}

function state_to_filter(state) {

    return {};
}

function filter_to_state(_) {
    return {};
}

let Filter = connect(state_to_filter, filter_to_state)(FilterUnConnected);

export {Filter}
