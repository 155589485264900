import React from "react";

import {InputFrameworkField} from "../InputFramework/InputFramework";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './single-small-choices.css';


//单选类型的字段(简短的)
class FieldSingleSmallChoices extends React.Component {
    constructor(props) {
        super(props);
        this.getContent = this.getContent.bind(this);
    }

    getPartPath() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');

        // 计算正确的updatePart空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        if (!spacePrivatePath) return null;
        let nowPagePath = spacePrivatePath.concat(['updatePart', 'nowPage',],);
        let nowPage = tool.getStateSpace(nowPagePath);
        if (!nowPage) nowPage = 1;
        let updatePartSpaceKey = `columnsUpdate_${nowPage}`;
        let columnsUpdatePartPath;
        columnsUpdatePartPath = spacePublicPath.concat([updatePartSpaceKey]);
        return columnsUpdatePartPath;
    }

    updateChoices() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);

        // 兼容获取columnsCreate/columnsUpdate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) columnsCreate = columnInfo;
        if (columnInfo) {
            let columnsUpdatePartPath = this.getPartPath();
            columnsUpdate = tool.getStateSpace(columnsUpdatePartPath);
        }


        let record = tool.getAttribute(paramModal, 'record');
        let fatherRecord = tool.getAttribute(paramModal, 'fatherRecord');
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        // let columnUpdatePath = columnsUpdatePath.concat([columnKey]);
        let columnCreatePath = columnsCreatePath.concat([columnKey]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);

        let columnsUpdatePartPath = this.getPartPath();
        if (action === 'update') {
            let path = tool.getAttribute(columnUpdate, 'path');
            if (!path) return null;

            let url = tool.get_url(path, false, {
                line_id: record.id,
                father_id: tool.getAttribute(fatherRecord, 'id'),
                record_id: record.id,
                page_size: 10000,
            });

            let requestParam = {
                url: url,
                success: data => {
                    // columnUpdate = Object.assign({}, columnUpdate);
                    // columnUpdate['choices'] = data.results;
                    // tool.setStateSpace(columnUpdatePath, columnUpdate);
                    if(columnInfo){
                        columnUpdate['choices'] = data.results;
                        let columnUpdatePath = columnsUpdatePartPath.concat([columnKey]);


                        tool.setStateSpace(columnUpdatePath, columnUpdate);
                    }else{
                        columnUpdate = Object.assign({}, columnUpdate);
                        columnUpdate['choices'] = data.results;
                        let columnUpdatePath = columnsUpdatePath.concat([columnKey]);
                        tool.setStateSpace(columnUpdatePath, columnUpdate);
                    }

                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);
        } else if (action === 'create' || action === 'copy') {
            let path = tool.getAttribute(columnCreate, 'path');
            if (!path) return null;

            let url = tool.get_url(path, false, {
                // father_id: fatherRecord.id,
                father_id: tool.getAttribute(fatherRecord, 'id'),
                page_size: 10000,
            });

            let requestParam = {
                url: url,
                success: data => {
                    columnCreate = Object.assign({}, columnCreate);
                    columnCreate['choices'] = data.results;
                    tool.setStateSpace(columnCreatePath, columnCreate);
                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);
        }

    }

    componentDidMount() {
        this.updateChoices();

        //如果是拷贝模式,需要提前写入数据,而不是等到变化
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        if (action === 'copy') {
            let infoModalPath = ['infoModal'];
            let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
            let defaultValue = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);
            let dataKey = `create__x`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            data = Object.assign({}, data);
            data[columnKey] = defaultValue;
            tool.setStateSpace(dataPath, data);
        }
    }

    getContent() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);

        let record = tool.getAttribute(paramModal, 'record');

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);

        // 兼容获取columnCreate/columnUpdate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) columnCreate = columnInfo;
        if (columnInfo) columnUpdate = columnInfo;

        let choices = tool.getAttribute(columnCreate, 'choices');
        if (action === 'update') choices = tool.getAttribute(columnUpdate, 'choices');

        // 是否是必须的
        let required = tool.getAttribute(columnCreate, 'required');
        if (action === 'update') required = tool.getAttribute(columnUpdate, 'required');

        // 默认值
        let defaultValue = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);

        let choiceReacts = [<option value={'0'} key={-1}>{'不选择'}</option>];
        if (required) choiceReacts = [];
        if (!choices) choices = [];
        let key_id = 1;
        for (let choice of choices) {
            // if (!choice.id) choice.id = key_id;
            let key = choice.id;
            if (!key) key = key_id;
            key_id++;
            choiceReacts.push(
                <option value={choice.value ? choice.value : choice.id} key={key}>{choice.help}</option>
            );
        }
        if (action === 'create') defaultValue = false;
        return (
            <select
                className={'choice-input'}
                defaultValue={defaultValue}
                // multiple={false}
                multiple={false}
                onChange={event => {
                    let target_value = event.target.value;
                    if (target_value === '0') target_value = null;

                    if (action === 'update') {
                        let dataKey = `update__${record.id}`;
                        let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                        let data = tool.getStateSpace(dataPath);
                        data = Object.assign({}, data);
                        data[columnKey] = target_value;
                        tool.setStateSpace(dataPath, data);
                    } else if (action === 'create' || action === 'copy') {
                        let dataKey = `create__x`;
                        let dataPath = infoModalPath.concat(['paramModal', dataKey]);
                        let data = tool.getStateSpace(dataPath);
                        data = Object.assign({}, data);
                        data[columnKey] = target_value;
                        tool.setStateSpace(dataPath, data);
                    }
                }}
            >
                {choiceReacts}
            </select>
        );
    }

    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);



        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);

        // 兼容获取columnCreate/columnUpdate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) columnCreate = columnInfo;
        if (columnInfo) columnUpdate = columnInfo;

        let title = tool.getAttribute(columnUpdate, 'title');
        let help = tool.getAttribute(columnUpdate, 'help');
        if (action === 'create' || action === 'copy') {
            title = tool.getAttribute(columnCreate, 'title');
            help = tool.getAttribute(columnCreate, 'help');
        }

        return <InputFrameworkField
            param={{
                getContent: this.getContent,
                title: title,
                help: help,
                action: action,
            }}

        />;
    }
}

function mapStateToSingle(state) {
    return {
        setting: state.setting,
    }
}

FieldSingleSmallChoices = connect(mapStateToSingle)(FieldSingleSmallChoices);

export {FieldSingleSmallChoices,};
