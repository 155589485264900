import {storeHandles} from "../store/handle";

let actionUpdateState = {type: 'actionUpdateState'};

function updateState(state, action) {
    if (!action.state) return Object.assign({}, state);
    return action.state;
}

storeHandles.registerHandle(actionUpdateState, updateState);
export {actionUpdateState};