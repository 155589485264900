import {store} from '../store/store';
import {actionUpdateState} from "./update-state";
import $ from 'jquery';
import {message} from 'antd';


class Tools {

    rightInfo = {};

    setTitle(title) {
        $('title').text(title);
    }

    get(args) {
        args['method'] = 'get';
        this.ajax(args);
    }

    post(args) {

        args['method'] = 'post';
        this.ajax(args);
    }

    put(args) {
        args['method'] = 'put';
        this.ajax(args);
    }

    patch(args) {
        args['method'] = 'patch';
        this.ajax(args);
    }

    delete(args) {
        args['method'] = 'delete';
        // let url = args['url'];
        // let last = url.charAt(url.length - 1);
        // if (last === '/') {
        //     url = url.slice(0, url.length - 1);
        //     args['url'] = url;
        // }

        this.ajax(args);
    }

    ajax(args) {
        let headers = args['headers'];
        if (!headers) headers = this.getStateSpace(['setting', 'authenticate', 'headers',]);
        args = Object.assign({}, args,);
        if (headers) args['headers'] = headers;

        // 添加ip地址
        let path = args['path'];
        if (path) args['url'] = this.get_url(path);

        $.ajax(args);
    }

    show_error(data, columnsMap) {
        const nowRole = this.getStateSpace(['setting', 'authenticate', 'role',]);
        const nowRoleLanguage = this.getStateSpace(['setting', nowRole, 'language',], 1,);
        let english2chines = {};

        let textInfo = 'info';
        let textError = 'error';
        let Unknown = 'Unknown exception';
        english2chines[textInfo] = '提示';
        english2chines[textError] = '异常';
        english2chines[Unknown] = '未知异常';


        if (nowRoleLanguage === 1) {
            textInfo = english2chines[textInfo];
            textError = english2chines[textError];
            Unknown = english2chines[Unknown];
        }
        columnsMap = Object.assign({}, columnsMap);
        columnsMap['info'] = {title: textInfo,};

        let info = null;

        let res_json = data['responseJSON'];

        if (res_json) {
            if (typeof (res_json) === 'string') {
                info = {'info': res_json};
            } else {
                info = Object.assign({}, res_json);
            }

            let keys = Object.keys(info);
            let new_infos = {};
            for (let i in keys) if (keys[i] !== 'fields_info') new_infos[keys[i]] = info[keys[i]];

            Object.keys(new_infos).map(key => {
                let keyInfo = key;
                if (columnsMap && columnsMap[key]) keyInfo = columnsMap[key].title;
                let temp = `${keyInfo}:${new_infos[key]}    `;
                if (temp.length > 200) temp = Unknown;
                message.error(temp);
                return null;
            });
        } else {
            let info = textError + '[' + data.responseText + ']';
            if (info.length > 200) info = Unknown;
            message.error(info).then(r => {
            });
        }
    }

    show_success(infos) {
        if (!Array.isArray(infos)) infos = [infos];
        for (let info of infos) message.success(info);
    }

    show_success_299(data) {
        for (let key in data) {
            if (!data.hasOwnProperty(key)) continue;
            let leftInfo = key;
            if (leftInfo === 'info') leftInfo = '提示';
            let info = `${leftInfo}:${data[key]}`;
            message.success(info);
        }
    }

    show_info(infos) {
        if (!Array.isArray(infos)) infos = [infos];
        for (let info of infos) message.info(info);
    }

    get_ip() {
        if (this._ip) return this._ip;
        let http = window.location.protocol;
        let host = window.location.host;
        let ip = `${http}//${host}`;
        ip = ip.replace(':3000', ':8000');
        ip = ip.replace(':3001', ':8001');
        this._ip = ip;
        return ip;
    }

    get_url(path, ip = false, url_args = {}, pk = null) {

        if (!ip) ip = this.get_ip();

        if (!ip || !path || !url_args) return null;


        if (path[0] !== '/') path = '/' + path;

        let args = Object.assign({}, url_args,);
        let keys = Object.keys(args);


        let url_args_str = '';
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let temp = `${key}=${args[key]}&`;
            url_args_str += temp;
        }
        if (url_args_str) url_args_str = url_args_str.slice(0, url_args_str.length - 1);

        let url = `${ip}${path}`;
        if (pk) url = `${url}${pk}`;

        if (url_args_str) {
            if (url.slice(url.length - 1, url.length) === '/') url = url.slice(0, url.length - 1);
            url = `${url}/?${url_args_str}`;
        }
        if (JSON.stringify(url_args) === '{}') {
            if (pk && url[url.length - 1] !== '/') url = url + '/';
        }

        return url;
    }

    // 拼接url路径
    path_join(path, param = {}, pk = null) {
        if (!path) return path;
        //是否携带主机地址
        let state = false;
        if (path.length > 7 && path.slice(0, 7) === 'http://') state = true;
        if (path.length > 8 && path.slice(0, 8) === 'https://') state = true;


        // 处理基础路径 media  => /media/ 未加http/https时才允许自动加
        if (!state) {
            if (!state && path[0] !== '/') path = '/' + path;
            // 结尾是否没有斜杠
            let state_end = path[path.length - 1] !== '/';

            //路径是否是访问静态文件
            let state_static = false;
            if (this.strIsInStr('media/', path)) state_static = true;
            if (this.strIsInStr('static/', path)) state_static = true;

            if (state_end && !state_static) path = path + '/';
        }

        //拼接pk
        if (pk) path = `${path}${pk}/`;

        //拼接get参数 {a:1, b:2, } => ?a=1&b=2
        param = Object.assign({}, param,);
        let keys = Object.keys(param);
        let param_str = '';
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let temp = `${key}=${param[key]}&`;
            param_str += temp;
        }
        if (param_str) {
            param_str = param_str.slice(0, param_str.length - 1);//去掉最后面的&
            param_str = '?' + param_str;
        }
        if (param_str) path = path + param_str

        //拼接ip
        if (!state) {
            let ip = this.get_ip();
            path = ip + path;
        }
        return path;
    }

    getStateReturnDefaultValue(space, null2value,) {
        if (space || null2value === false) return space;
        return null2value;
    }

    getStateSpace(spacePath, null2value = false,) {
        if (!spacePath) return this.getStateReturnDefaultValue(null, null2value,);
        //通过路径从state存储空间获得子空间.例spacePath = ['setting', 'table',].
        let space = store.getState();
        for (let path of spacePath) {
            if (!space) return this.getStateReturnDefaultValue(null, null2value,);
            space = space[path];
        }
        if (null2value && !space) return null2value;
        return this.getStateReturnDefaultValue(space, null2value,);
    }

    setStateSpace(spacePath, space) {
        if (!spacePath) return null;
        let updateFatherSpace = (fatherSpacePath, subKey, subSpace) => {
            let fatherSpace = this.getStateSpace(fatherSpacePath);
            fatherSpace = Object.assign({}, fatherSpace);
            fatherSpace[subKey] = subSpace;
            return fatherSpace;
        };


        let subSpace = space;
        let fatherSpacePath = spacePath.slice(0, spacePath.length - 1);

        for (let i = spacePath.length - 1; i >= 0; i--) {
            let subKey = spacePath[i];

            subSpace = updateFatherSpace(fatherSpacePath, subKey, subSpace);
            fatherSpacePath = fatherSpacePath.slice(0, fatherSpacePath.length - 1);
        }
        let action = Object.assign({}, actionUpdateState, {state: subSpace});
        store.dispatch(action);
    }

    getAttribute(targetObject, attributePath, defaultValue,) {
        // 准备返回值
        let value;
        value = defaultValue;

        // 母数据不存在
        if (!targetObject) return value;

        // 非数组路径修正
        if (!Array.isArray(attributePath)) attributePath = [attributePath];

        // 初始化当前值为母数据
        let attribute = targetObject;

        // 逐步获取当前值
        const allowType = [
            '[object Object]',
            '[object Location]',
        ];
        for (let i = 0; i < attributePath.length; i++) {
            let path = attributePath[i];
            attribute = attribute[path];

            // 当前值不支持继续迭代,返回默认值  除非后面无需迭代了
            const attributeType = Object.prototype.toString.call(attribute);
            if (!this.isInArray(allowType, attributeType) && i < attributePath.length - 1) {
                return value;
            }
        }

        if (attribute === undefined) return value;
        value = attribute;
        return value;
    }

    isInArray(array, value) {
        let index = array.findIndex(item => item === value);
        return index !== -1;
    }

    strIsInStr(str1, str2) {
        let state;
        state = str2.indexOf(str1);
        return state !== -1;
    }

    utc_to_china(utc_str) {
        if (!utc_str) return null;
        let date_obj = new Date(utc_str);
        date_obj.setHours(date_obj.getHours() + 8);
        let year = date_obj.getFullYear();
        let month = date_obj.getMonth() + 1;
        let day = date_obj.getDate();

        let hours = date_obj.getHours();
        let minutes = date_obj.getMinutes();
        return `${year}年${month}月${day}日 ${hours}点${minutes}分`;
    }

    getUrlParam(key) {
        let query = window.location.search.substring(1);
        let values = query.split("&");
        for (let i = 0; i < values.length; i++) {
            let pair = values[i].split("=");
            if (pair[0] === key) {
                return pair[1];
            }
        }
        return null;
    }

    roleInfoHandler(roleInfo) {
        let setting = this.getStateSpace(['setting']);
        // 未注册的角色过滤掉
        let temp = [];
        for (let item of roleInfo) if (setting[item.code]) temp.push(item);
        return temp;
    }

    //生成从start到end的随机数
    getRandomInt(start, end) {
        switch (arguments.length) {
            case 1:
                return parseInt((Math.random() * start + 1) + '', 10);
            case 2:
                return parseInt(Math.random() * (end - start + 1) + start, 10);
            default:
                return 0;
        }
    }
}

let tool = new Tools();

export {tool,}
