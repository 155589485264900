import React from "react";
import {Button, Modal,} from "antd";

import {tool} from "../../tools/tools";


function Video(param) {
    let value = param.value;
    // console.log(param)
    // spacePrivatePath record
    let id = tool.getAttribute(param, ['record', 'id',]);
    let columnKey = tool.getAttribute(param, ['columnKey',]);
    let spaceKey = `${columnKey}__${id}`;
    let spacePrivatePath = tool.getAttribute(param, 'spacePrivatePath');

    let showSpacePath = spacePrivatePath.concat(['VideoColumnSpace', spaceKey,]);
    let modalWidthPath = spacePrivatePath.concat(['VideoColumnSpace', 'modalWidth',]);

    let modalWidth = tool.getStateSpace(modalWidthPath);
    if (modalWidth) modalWidth = 0;
    let showSpace = tool.getStateSpace(showSpacePath);

    if (!value) return null;

    let url = tool.get_url(value);
    let video_id = Math.random().toString();

    let onClickButton = () => {
        tool.setStateSpace(showSpacePath, true);
    };

    let onClose = () => {
        tool.setStateSpace(showSpacePath, false);
        let video_obj = document.getElementById(video_id);
        video_obj.pause();
    };

    // 获取宽度
    let setWidth = () => {
        let modalWidth = tool.getStateSpace(modalWidthPath);
        if (!modalWidth) return null;

        let video_obj = document.getElementById(video_id);

        if (!video_obj) return null;

        if (video_obj.width) tool.setStateSpace(modalWidthPath, video_obj.width);
    };

    let setWidthOut = () => {
        setTimeout(setWidth, 100);
    };

    return (<div>
            <Button type={'link'} onClick={onClickButton}>播放</Button>
            <Modal
                // title="视频预览"
                // visible={true}
                visible={showSpace}
                // onOk={() => {}}
                onCancel={onClose}
                footer={null}
                width={modalWidth}
            >

                <video width="320" height="240" controls id={video_id}>
                    {/*<source src={url} type="video/mp4"/>*/}
                    <source src={url}/>
                    您的浏览器不支持视频播放器。
                </video>
                {setWidthOut()}
            </Modal>

        </div>
    )
}

export {Video};

