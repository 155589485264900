import React from "react";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './img-path.css';
import {Image} from "antd";


//图片路径类型的字段
class FieldDetailImgPath extends React.Component {

    getContent() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let recordId = tool.getAttribute(this, ['props', 'param', 'record', 'id',]);

        let columnsDetailLinePath = spacePublicPath.concat(['columnsDetailLine']);
        let columnsDetailLineSpace = tool.getStateSpace(columnsDetailLinePath);

        let column = tool.getAttribute(columnsDetailLineSpace, columnKey);
        let title = tool.getAttribute(column, 'title');

        // 获取值
        let dataKey = `detail__${recordId}`;
        let paramModalPath = ['infoModal', 'paramModal',]
        let paramModal = tool.getStateSpace(paramModalPath);
        paramModal = Object.assign({}, paramModal);
        let data = paramModal[dataKey];
        if (!data) data = {};


        //得到路径值
        let valueImg = null;
        let valueImgPath = data[columnKey];
        if (valueImgPath) {
            // let src = tool.get_url(valueImgPath);
            let src = tool.path_join(valueImgPath);
            valueImg = (<div className={'img-div'}>
                <Image
                    src={src}
                    style={{
                        height: '50px',
                        width: '50px',
                    }}
                    width={'100%'}
                    height={'100%'}
                />
            </div>);
        }


        return (
            <div>
                <div className={'detail-title'}>
                    {title}
                </div>
                {valueImg}
            </div>
        )
    }

    render() {
        let content = this.getContent();
        return (
            <div>
                {content}
            </div>
        )
    }
}

function mapStateToUpdate(state) {
    return {
        setting: state.setting,
    }
}

FieldDetailImgPath = connect(mapStateToUpdate)(FieldDetailImgPath);

export {FieldDetailImgPath,};
