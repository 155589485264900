import React from 'react';
import {connect} from 'react-redux';
import {tool} from "../../../tools/tools";
import './dynamic_filter.css';

import {Select} from 'antd';

const {Option} = Select;

let lastUpdate = 0;


//动态过滤器组件
class DynamicFilterUnConnected extends React.Component {

    constructor(props) {
        super(props);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.updateData = this.updateData.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    // 更新表格数据
    updateData(search) {
        // 私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);

        // 获取搜索关键字
        let spacePublicPath = tool.getAttribute(this.props.param, 'spacePublicPath',);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let dynamicFilter = tool.getAttribute(spacePublic, 'dynamicFilter');
        let searchKey = tool.getAttribute(dynamicFilter, 'searchKey',);

        // 设置加载状态及相关参数
        spacePrivate = Object.assign(spacePrivate, {
            stateTableOnload: 'dynamic',
            'searchKey': searchKey,
            'dynamicSearchWord': search,
        });

        tool.setStateSpace(spacePrivatePath, spacePrivate,)
    }

    updateChoices() {
        // 如果当前点击与父组件不相同则不更新
        let fatherRecord = tool.getAttribute(this, ['props', 'param', 'fatherRecord',]);
        if (fatherRecord) {
            // 仅对当前点击更新
            let fatherLastClickRecordId = tool.getAttribute(this, ['props', 'param', 'fatherLastClickRecordId',]);
            if (fatherRecord.id !== fatherLastClickRecordId) return;
        }

        let now = (new Date()).valueOf();
        let diff = now - lastUpdate;
        if (diff < 3000) return null;
        lastUpdate = (new Date()).valueOf();

        let spacePublicPath = tool.getAttribute(this.props.param, 'spacePublicPath',);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let dynamicFilter = tool.getAttribute(spacePublic, 'dynamicFilter');
        if (!dynamicFilter) return null;

        let path = tool.getAttribute(dynamicFilter, 'path',);
        let getFatherId = tool.getAttribute(this, ['props', 'getFatherId',]);
        let fatherId = getFatherId();
        if (!fatherId) fatherId = null;

        let param = {
            page_size: 10000,
            father_id: fatherId,
        };
        path = tool.path_join(path, param,);
        let requestParam = {
            url: path,
            success: data => {
                let results = tool.getAttribute(data, 'results', []);
                let dynamicFilterPath = spacePublicPath.concat(['dynamicFilter']);
                let spacePublic = tool.getStateSpace(spacePublicPath);
                let dynamicFilter = tool.getAttribute(spacePublic, 'dynamicFilter');
                dynamicFilter = Object.assign(dynamicFilter, {
                    menu: results,
                },);
                tool.setStateSpace(dynamicFilterPath, dynamicFilter);
            },
            error: error => {
                tool.show_error(error);
            },
        };
        tool.get(requestParam);

    }

    handleMenuClick(event) {
        this.updateData(event.key);
    }

    handleChange(value) {
        if (value === -1) value = '';
        this.updateData(value);
    }

    getComponent(dynamicFilter,) {
        let text = tool.getAttribute(dynamicFilter, 'text',);
        let menu = tool.getAttribute(dynamicFilter, 'menu', [],);

        let item = [];
        item.push(
            <Option value={-1} key={-1}>{'不选择'}</Option>
        );
        for (const line of menu) {
            let id = line.id;
            let help = line.help;
            item.push(
                <Option value={id} key={id}>{help}</Option>
            );
        }

        menu = (
            <Select defaultValue={text} style={{width: 120}} onChange={this.handleChange}>
                {item}
            </Select>
        );
        return menu;
    }

    render() {
        this.updateChoices();
        let spacePublicPath = tool.getAttribute(this.props.param, 'spacePublicPath',);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let dynamicFilter = tool.getAttribute(spacePublic, 'dynamicFilter');
        if (!dynamicFilter) return null;
        return this.getComponent(dynamicFilter,);
    }

}

function state_to_dynamic(state) {

    return {};
}

function dynamic_to_state(_) {
    return {};
}

let DynamicFilter = connect(state_to_dynamic, dynamic_to_state)(DynamicFilterUnConnected);

export {DynamicFilter,}
