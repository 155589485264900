import React from "react";
import {Tag, Tooltip,} from 'antd';


function strToUtf8(str) {
    let encoder = new TextEncoder('utf8');
    return encoder.encode(str);
}

function getLabelReact(label) {
    let colors = [
        "magenta",
        "red",
        "volcano",
        "orange",
        "gold",
        "lime",
        "green",
        "cyan",
        "blue",
        "purple",
    ];
    let valueUtf8s = strToUtf8(label);
    let valueMap = 0;
    for (let u of valueUtf8s) valueMap += u;
    valueMap /= 35;
    valueMap = valueMap.toString();
    let index = valueMap.charAt(valueMap.length - 1);
    index = Number(index);
    let color = colors[index];

    return <Tag color={color} key={label}>{label}</Tag>;
}


function label(param) {
    let value = param.value;
    if (!value) value = [];


    let helps = '';
    let labelReacts = value.map(dictLabel => {
        let textLabel = dictLabel.help;
        helps = helps + ' ' + textLabel;
        return getLabelReact(textLabel);
    });

    return (<Tooltip placement="topLeft" title={helps}>
        {labelReacts}
    </Tooltip>);


}

export {label, getLabelReact,};

