import React from "react";
import {Button} from 'antd';

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";
import './img-head.css';
import $ from "jquery";
import {Image, Tooltip} from "antd";
import CropperComponent from './../img-head/cropper-component'

const MAX_FILE_SIZE = 500 * 1024 * 1024 // 文件最大限制为500M 裁剪


//图片裁剪类型的字段
class FieldImageHead extends React.Component {
    constructor(props) {
        super(props);
        this.getCorp = this.getCorp.bind(this);
        this.getChoiceButton = this.getChoiceButton.bind(this);
        this.state = {
            nowSrc: null,
            width: 0,
            height: 0,
            url_back: null,

            isShow: false,
            file: null,
            fileUrl: null,
        };
    }

    dealImage(base64, w, callback) {
        var newImage = new global.Image();
        var quality = 0.6;    //压缩系数0-1之间
        newImage.src = base64;
        newImage.setAttribute("crossOrigin", 'Anonymous');	//url为外域时需要
        var imgWidth, imgHeight;
        newImage.onload = function () {
            imgWidth = this.width;
            imgHeight = this.height;
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            if (Math.max(imgWidth, imgHeight) > w) {
                if (imgWidth > imgHeight) {
                    canvas.width = w;
                    canvas.height = w * imgHeight / imgWidth;
                } else {
                    canvas.height = w;
                    canvas.width = w * imgWidth / imgHeight;
                }
            } else {
                canvas.width = imgWidth;
                canvas.height = imgHeight;
                quality = 0.6;
            }
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
            var base64 = canvas.toDataURL("image/jpeg", quality); //压缩语句
            // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
            // while (base64.length / 1024 > 150) {
            // 	quality -= 0.01;
            // 	base64 = canvas.toDataURL("image/jpeg", quality);
            // }
            // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
            // while (base64.length / 1024 < 50) {
            // 	quality += 0.001;
            // 	base64 = canvas.toDataURL("image/jpeg", quality);
            // }
            callback(base64);//必须通过回调函数返回，否则无法及时拿到该值
        }
    }

    fileChange = event => {
        let files = event.target.files;
        let file = null;
        if (files.length > 0) file = event.target.files[0];
        if (!file) return;
        if (file.size > MAX_FILE_SIZE) {
            console.log('文件过大');
            return;
        }

        // 转换为
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = event => {
            let dataBase64 = event.target.result;
            this.dealImage(dataBase64, 800, dataBase64 => {
                let arr = dataBase64.split(',');
                let mime = arr[0].match(/:(.*?);/)[1];
                let bStr = atob(arr[1]);
                let n = bStr.length;
                let u8arr = new Uint8Array(n);
                while (n--) u8arr[n] = bStr.charCodeAt(n);
                let file_obj = new File([u8arr], file.name, {type: mime});
                this.setState({
                    file: file_obj,
                    isShow: true,
                    fileName: file_obj.name,
                })
            })
        }


        // this.setState({
        //     file: file,
        //     isShow: true,
        //     fileName: file.name,
        // })
    }

    getImgUrl = () => blob => {
        const str = URL.createObjectURL(blob)
        this.setState({fileUrl: str});

        let file = new window.File([blob], this.state.fileName, {type: 'image/jpeg'})


        // 文件更新到数据区
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let record = tool.getAttribute(paramModal, 'record');

        if (action === 'update') {
            let dataKey = `update__${record.id}`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            data = Object.assign({}, data);
            data[columnKey] = file;
            tool.setStateSpace(dataPath, data);
        } else if (action === 'create' || action === 'copy') {
            let dataKey = `create__x`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            data = Object.assign({}, data);
            data[columnKey] = file;
            tool.setStateSpace(dataPath, data);
        }
    }

    getOriginImgReact() {

        // 得到默认值
        let defaultValue = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);
        let src = tool.get_url(defaultValue);
        return (<div className={'img-head-fields-img-path-img-border'}>
            {defaultValue ? <Image
                src={src}
                onLoad={imgObj => {
                    // let image = new global.Image();
                    // image.src = imgObj.src;

                    // imgObj.height = 80;
                    // imgObj.width = 80;

                }}
                // className={'img-head-fields-img-path-img-border'}
            /> : null}
            {defaultValue ? null : '空的'}
        </div>);
    }

    getCorp(ratio) {
        let fileUrl = this.state.fileUrl;
        if (!this.state.file) return this.getOriginImgReact();

        let space;
        if (this.state.isShow) space = this.getOriginImgReact();

        return (
            <div className="app">
                {space}
                <div className="half-area">

                    <div className="img-container">
                        {fileUrl && (
                            <img
                                className={"border-image-in"}
                                src={fileUrl}
                                alt="fileUrl"
                            />
                        )}
                    </div>
                </div>

                {this.state.isShow && (
                    <CropperComponent
                        file={this.state.file}
                        onClose={() => {
                            this.setState({isShow: false})
                        }}
                        onSubmit={this.getImgUrl()}
                        ratio={ratio}
                        show={this.state.isShow}
                    />
                )}


            </div>
        )
    }

    getChoiceButton() {
        if (this.state.isShow) return null;

        let inputId = Math.ceil(Math.random() * 10).toString();

        return (
            <div className={'fields-img-path-button'}>
                <input
                    id={inputId}
                    className={'file-input'}
                    type={'file'}
                    onChange={this.fileChange}
                    accept={"image/gif, image/jpeg,image/jpg,image/png,image/bmp,"}
                />
                <div className={'fields-img-path-button-one'}>
                    <Button
                        type={'primary'}
                        onClick={() => $(`#${inputId}`).click()}
                    >
                        点击换图
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);

        //  计算ratio
        let ratio;
        if (action === 'update') ratio = tool.getAttribute(columnUpdate, 'ratio');
        if (action === 'create') ratio = tool.getAttribute(columnCreate, 'ratio');
        if (action === 'copy') ratio = tool.getAttribute(columnCreate, 'ratio');

        // 兼容获取columnCreate/columnUpdate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) columnCreate = columnInfo;
        if (columnInfo) columnUpdate = columnInfo;

        let title = tool.getAttribute(columnUpdate, 'title');
        let help = tool.getAttribute(columnUpdate, 'help');
        if (action === 'create' || action === 'copy') {
            title = tool.getAttribute(columnCreate, 'title');
            help = tool.getAttribute(columnCreate, 'help');
        }


        let nowImageReact = this.getCorp(ratio);
        let choiceButton = this.getChoiceButton();

        return (<div>
            <Tooltip title={help}>
                <div style={{marginBottom: '5px',}}>
                    <div className={'title'}>
                        {title}
                    </div>
                </div>
            </Tooltip>
            {nowImageReact}

            {choiceButton}
        </div>);
    }
}

function mapStateToUpdate(state) {
    return {
        setting: state.setting,
    }
}

FieldImageHead = connect(mapStateToUpdate)(FieldImageHead);

export {FieldImageHead,};
