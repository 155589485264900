import React from "react";
import {Modal, Divider} from "antd";

import {tool} from "../tools/tools";
import {connect} from "react-redux";


//数据详情
class DetailLine extends React.Component {
    constructor(props) {
        super(props);
        this.getFieldReact = this.getFieldReact.bind(this);
        this.getFieldType = this.getFieldType.bind(this);
        this.getContent = this.getContent.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.getColumnsUpdate = this.getColumnsUpdate.bind(this);
    }

    updateData() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Detail') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let detailOnload = tool.getAttribute(paramModal, 'detailOnload');
        let record = tool.getAttribute(paramModal, 'record');
        let fatherRecord = tool.getAttribute(paramModal, 'fatherRecord');
        let recordId = record.id;
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let filter = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filter) {
            filter = tool.getAttribute(spacePublic, ['filters', 'default',])
        }

        let requestPathPath = spacePublicPath.concat(['path']);
        let requestPath = tool.getStateSpace(requestPathPath);
        if (!detailOnload) return null;
        let startUpdate = () => {

            //请求数据
            let paramUrl = {};
            if (fatherRecord) paramUrl['father_id'] = fatherRecord.id;
            if (filter) paramUrl['filter'] = filter;


            let url = tool.get_url(requestPath, false, paramUrl, recordId);
            let requestParam = {
                url: url,
                success: data => {
                    //更新数据到Modal参数区的特定区域
                    let dataKey = `detail__${recordId}`;
                    let paramModalPath = ['infoModal', 'paramModal',];
                    // paramModal = tool.getStateSpace(paramModalPath);
                    paramModal = Object.assign({}, paramModal, {
                        detailOnload: false,
                    });
                    paramModal[dataKey] = data;
                    tool.setStateSpace(paramModalPath, paramModal);
                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);

        };
        setTimeout(startUpdate, 100);
    }

    getFieldType(param) {
        let columnKey = param.columnKey;
        let data = param.data ? param.data : {};
        let columnsDetailLine = param.columnsDetailLine;
        let columnInfo = columnsDetailLine[columnKey];

        //字段类型
        let fieldType = columnInfo.type;
        if (fieldType) return fieldType;

        //根据数据进行推定
        if (data) {
            let isHelpArray = () => {
                let isArray = Array.isArray(data);
                if (!isArray) return false;

                if (data.length < 1) return 'emptyArray';

                let first = data[0];
                if (!first) return false;
                if (first.id && first.help) return 'helpArray';

                return false;

            };
            let type = isHelpArray();
            if (type) fieldType = type;
        }

        if (!fieldType) fieldType = 'char';
        return fieldType;
    }

    getFieldReact(param) {
        let columnKey = param.columnKey;

        //字段类型
        let fieldType = this.getFieldType(param);
        let fieldMapping = tool.getStateSpace(['setting', 'fieldDetailMapping',]);
        let func = tool.getAttribute(fieldMapping, fieldType);
        if (!func) func = tool.getAttribute(fieldMapping, 'char');

        return (
            <div key={columnKey}>
                {func(param)}
                <Divider/>
            </div>
        );
    }

    getColumnsUpdate() {
        let infoModal = tool.getAttribute(this, ['props', 'infoModal',]);

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');

        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        //更新字段
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate',]);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);

        // 是否使用过滤器的字段
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }
        let filtersInfo = tool.getAttribute(spacePublic, 'filters');
        let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
        let filterNow = tool.getAttribute(filtersChoices, filterValue);
        let filterColumnsUpdate = tool.getAttribute(filterNow, 'columnsUpdate');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterColumnsUpdate) {
            columnsUpdate = filterColumnsUpdate;
        }
        return columnsUpdate;
    }

    getContent() {
        let infoModalPath = ['props', 'infoModal',];
        let infoModal = tool.getAttribute(this, infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Detail') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let onload = tool.getAttribute(paramModal, 'onload');
        let record = tool.getAttribute(paramModal, 'record');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let spaceRowPath = tool.getAttribute(paramModal, 'spaceRowPath');

        //显示字段
        // let columnsDetailLinePath = spacePublicPath.concat(['columnsDetailLine',]);
        // let columnsDetailLine = tool.getStateSpace(columnsDetailLinePath);

        let columnsDetailLine = tool.getAttribute(spacePublic, 'columnsDetailLine');

        // 是否使用过滤器的字段
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }
        let filtersInfo = tool.getAttribute(spacePublic, 'filters');
        let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
        let filterNow = tool.getAttribute(filtersChoices, filterValue);
        let filterColumnsDetailLine = tool.getAttribute(filterNow, 'columnsDetailLine');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterColumnsDetailLine) {
            columnsDetailLine = filterColumnsDetailLine;
        }


        if (!columnsDetailLine) return null;

        let fieldReacts = [];
        for (let columnKey in columnsDetailLine) {
            //得到具体的字段
            if (!columnsDetailLine.hasOwnProperty(columnKey)) continue;

            // 是否显示这个字段
            let state = true;
            let columnDetailLine = columnsDetailLine[columnKey];
            let fun_is_show = tool.getAttribute(columnDetailLine, 'is_show');

            if (fun_is_show) {
                let param = {infoModalPath: infoModalPath, infoModal: infoModal,};
                state = fun_is_show(param);
            }

            if (state) {
                fieldReacts.push(this.getFieldReact({
                    infoModal: infoModal,
                    paramModal: paramModal,
                    onload: onload,
                    record: record,
                    spacePrivatePath: spacePrivatePath,
                    spacePublicPath: spacePublicPath,
                    spaceRowPath: spaceRowPath,

                    columnsDetailLine: columnsDetailLine,
                    columnKey: columnKey, // 详情列提交时的key
                    action: 'detail',
                }));
            }
        }

        return fieldReacts;
    }

    onOk() {
        //左键触发检测程序允许正常运行
        tool.rightInfo = Object.assign({}, tool.rightInfo, {
            sign: false,
        },);

        //关闭对话框/清空数据
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        infoModal = Object.assign({}, infoModal, {
            nowType: 'Update',
        });

        tool.setStateSpace(infoModalPath, infoModal,);
    }

    onCancel() {
        //左键触发检测程序允许正常运行
        tool.rightInfo = Object.assign({}, tool.rightInfo, {
            sign: false,
        },);

        //关闭对话框/清空数据
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        infoModal = Object.assign({}, infoModal, {
            nowType: null,
            paramModal: null,
        });

        tool.setStateSpace(infoModalPath, infoModal,);
    }

    render() {
        this.updateData();
        let infoModal = tool.getAttribute(this, ['props', 'infoModal',]);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Detail') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let detailOnload = tool.getAttribute(paramModal, 'detailOnload');
        if (detailOnload) return '加载中...';

        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');

        let content = this.getContent();

        let textTitlePath = spacePublicPath.concat(['text__detail_title']);
        let textTitle = tool.getStateSpace(textTitlePath);
        if (!textTitle) textTitle = '数据详情';

        // 是否允许编辑
        let columnsUpdate = this.getColumnsUpdate();
        let okText = '好的';
        let onOk = () => this.onCancel();
        if (columnsUpdate) {
            okText = '切换到编辑模式';
            onOk = () => this.onOk();
        }
        return (
            <div>
                <Modal
                    title={textTitle}
                    visible={true}
                    okText={okText}
                    cancelText={'关闭'}
                    onOk={onOk}
                    onCancel={() => this.onCancel()}
                    // footer={null}
                >
                    {content}
                </Modal>
            </div>
        );
    }
}

function mapStateToDetail(state) {
    return {
        infoModal: state.infoModal,
    }
}

DetailLine = connect(mapStateToDetail)(DetailLine);

export {DetailLine};